import React, { FunctionComponent } from "react";
import * as Styled from "../Managing.styled";
import InputText from "../../../../../components/InputText/InputText";
import { Button } from "carbon-components-react";
import { OptionsContext } from "../../../../../context/OptionsContext/OptionsContext";
import { OptionsType } from "../../../../../types/Options";

type Props = {
  options?: OptionsType | null;
  onSubmitHandler: () => void;
  register: any;
  openModalHandler: () => void;
};

const StageI: FunctionComponent<Props> = ({
  options,
  onSubmitHandler,
  register,
  openModalHandler,
}) => {
  console.log(options && options[1]);
  return (
    <Styled.Container>
      <Styled.Wrapper>
        <Styled.Header>Zarządzenie Etapem I</Styled.Header>
        <Styled.DateWrapper>
          <Styled.SubHeader>Data końca pierwszego etapu:</Styled.SubHeader>
          <Styled.SubHeader>
            {options && options[0].value.replace("T00:00:00+00:00", "")}
          </Styled.SubHeader>
        </Styled.DateWrapper>
        <form onSubmit={onSubmitHandler}>
          <InputText
            register={register}
            type={"date"}
            helperText={"Wprowadź date"}
            id={"date"}
            labelText={"Nowa data końca pierwszego etapu:"}
            placeholder={""}
            disabled={false}
            invalid={false}
          />
          <Styled.ButtonsWrapper>
            <Button type={"submit"}> Zmień date </Button>
            <Button
              onClick={() => openModalHandler()}
              disabled={options && options[1].value ? options[1].value : false}
            >
              {options && options[1].value
                ? "Opublikowano"
                : "Opublikuj wyniki"}
            </Button>
          </Styled.ButtonsWrapper>
        </form>
      </Styled.Wrapper>
    </Styled.Container>
  );
};

export default StageI;
