import styled from "styled-components";
import {
  BaseContainer,
  BasicDescending,
  HeaderH2,
  Section,
} from "../../../../styles/BasicStyles";
import ThemeStyle from "../../../../styles/ThemeStyles";

export const SecondLevelSection = styled(Section)`
  background-color: ${ThemeStyle.colors.sections.secondLevelSection};
`;

export const Container = styled.div`
  ${BaseContainer};
`;

export const Title = styled.h2`
  ${HeaderH2}
`;

export const Descending = styled.p`
  ${BasicDescending};
  margin-top: 3rem;
  width: 80%;
  line-height: 2.8rem;
`;
