import React, { FunctionComponent, useState } from "react";
import InputText from "../../../../components/InputText/InputText";
import * as Styled from "../../LoginScreen.styled";
import { Button, Loading } from "carbon-components-react";
import { SubmitHandler, useForm } from "react-hook-form";
import api from "../../../../services/api";
import { AuthContext } from "../../../../context/AuthContext";
import { NavigateFunction, useNavigate } from "react-router-dom";
import Dropdown from "../../../../components/Dropdown/Dropdown";
import CustomModal from "../../../../components/CustomModal/CustomModal";

type Props = {};
type Inputs = {
  mail: string;
  password: string;
  user: any;
};

const LoginForm: FunctionComponent<Props> = () => {
  const { loadUser } = React.useContext(AuthContext);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<Inputs>({
    mode: "onSubmit",
    reValidateMode: "onChange",
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: false,
  });

  const [errMsg, setErrMsg] = useState<string>("");
  const [loadingState, setLoadingState] = useState<boolean>(false);
  const navigate: NavigateFunction = useNavigate();

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    setLoadingState(true);
    try {
      await api.post("/login", {
        username: data.mail,
        password: data.password,
      });
      setLoadingState(false);
      loadUser();
    } catch (err) {
      if (err) {
        setLoadingState(false);
        setErrMsg("Błędne dane");
        return Promise.reject(err);
      }
    }
  };

  return (
    <>
      {loadingState && (
        <CustomModal>
          <Loading />
        </CustomModal>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <InputText
          register={register("mail", {
            required: true,
            pattern: /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i,
          })}
          helperText={"Wprowadź adres e-mail"}
          id={"email"}
          labelText={"E-mail"}
          placeholder={"j.kowalski@gmail.com"}
          disabled={false}
          invalid={false}
          errors={
            errors.mail && (
              <Styled.ErrorMessage>
                Wprowadź poprawny adres e-mail
              </Styled.ErrorMessage>
            )
          }
        />
        <InputText
          register={register("password", { required: true })}
          helperText={"Wprowadź hasło"}
          id={"password"}
          labelText={"Hasło"}
          type={"password"}
          placeholder={"Hasło"}
          disabled={false}
          invalid={false}
          errors={
            errors.password && (
              <Styled.ErrorMessage>Wprowadź hasło</Styled.ErrorMessage>
            )
          }
        />
        <Styled.ButtonWrapper>
          <Button type={"submit"}> Zaloguj się </Button>
          <a
            onClick={() => {
              navigate("/reset-password");
            }}
            style={{ textDecoration: "underline", color: "#c6c6c6" }}
          >
            Zapomniałem hasła
          </a>
        </Styled.ButtonWrapper>
        {errMsg ? <Styled.ErrorMessage>{errMsg}</Styled.ErrorMessage> : ""}
      </form>
    </>
  );
};

export default LoginForm;
