import styled from "styled-components";

export const Coordinators = styled.div`
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  width: 30%;
`;
export const Title = styled.p`
  font-size: 1.4rem;
  font-weight: bold;
  margin-bottom: 3rem;
`;

export const Coordinator = styled.div`
  width: 100%;
  border-bottom: 1px solid grey;
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
`;

export const Label = styled.p`
  font-weight: bold;
  margin-bottom: 0.4rem;
  margin-right: 5rem;
`;
export const Detail = styled.p`
  margin-bottom: 0.4rem;
`;
