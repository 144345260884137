import styled, { css } from "styled-components";

export const FormWrapper = styled.div`
  margin-top: 2rem;

  button {
    margin-top: 0.3rem;
  }

  .cds--label {
    color: black;
  }
  .cds--text-input__field-outer-wrapper {
    border: 1px solid grey;
    width: 10%;
  }

  .cds--text-input,
  .cds--text__input,
  .cds--text-input--md {
    color: #000;
  }
`;

export const ApplicationWrapper = styled.div`
  padding: 2rem;
  background-color: #e8daff;
  border-radius: 10px;
  margin-right: 3rem;
`;

export const Label = styled.p`
  font-weight: bold;
  margin-bottom: 0.4rem;
`;

export const Detail = styled.p`
  margin-bottom: 0.4rem;
`;

export const Title = styled.h2`
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1rem;

  &.gradeTitle {
    margin-top: 4rem;
  }
`;
