import React, { FunctionComponent } from "react";
import * as Styled from "./SuccessScreen.styled";
import { Button } from "carbon-components-react";
import { NavigateFunction, useNavigate } from "react-router-dom";

type Props = {};

const SuccessScreen: FunctionComponent<Props> = () => {
  const navigate: NavigateFunction = useNavigate();

  return (
    <Styled.SuccessWrapper>
      <Styled.SubHeader>Możesz teraz się zalogować</Styled.SubHeader>
      <Button
        onClick={() => {
          navigate("/");
        }}
      >
        Zaloguj się
      </Button>
    </Styled.SuccessWrapper>
  );
};

export default SuccessScreen;
