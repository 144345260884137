import React, { useEffect } from "react";
import { AxiosError } from "axios";
import api from "../../services/api";
import { optionsReducer } from "./OptionsReducer";
import { OptionsContext } from "./OptionsContext";

type Props = {
  children?: React.ReactNode;
};
export const OptionsProvider: React.FC<Props> = ({ children }) => {
  const [session, dispatch] = React.useReducer(optionsReducer, {
    options: null,
    state: "LOAD_OPTIONS",
  });

  const loadOptions = () => {
    return api
      .get("/options")
      .then((r) => {
        dispatch({
          type: "LOAD_OPTIONS",
          options: r.data["hydra:member"],
        });
      })
      .catch((error: AxiosError) => {
        console.log("problem with options");
        return Promise.reject(error);
      });
  };

  useEffect(() => {
    if (!session.options) {
      loadOptions();
    }
  }, [session.options]);

  return (
    <OptionsContext.Provider value={{ session, dispatch, loadOptions }}>
      {children}
    </OptionsContext.Provider>
  );
};
