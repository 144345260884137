import React, { FunctionComponent } from "react";
import * as Styled from "./StudentDetails.styled";
import Student from "../../types/Student";

type Props = {
  student: Student;
};

const StudentDetails: FunctionComponent<Props> = ({ student }) => {
  return (
    <>
      <Styled.MainWrapper>
        <Styled.StudentWrapper>
          <Styled.Title>Dane ucznia</Styled.Title>
          <Styled.ColumnWrapper>
            <Styled.RowWrapper>
              <Styled.Label>Imię</Styled.Label>
              <Styled.Detail>{student.firstName}</Styled.Detail>
            </Styled.RowWrapper>
            <Styled.RowWrapper>
              <Styled.Label>Nazwisko</Styled.Label>
              <Styled.Detail>{student.lastName}</Styled.Detail>
            </Styled.RowWrapper>
            <Styled.RowWrapper>
              <Styled.Label>Adres e-mail</Styled.Label>
              <Styled.Detail>{student.email}</Styled.Detail>
            </Styled.RowWrapper>
            <Styled.RowWrapper>
              <Styled.Label>Numer telefonu</Styled.Label>
              <Styled.Detail>{student.phoneNumber}</Styled.Detail>
            </Styled.RowWrapper>
            <Styled.RowWrapper>
              <Styled.Label>Data urodzenia</Styled.Label>
              <Styled.Detail>
                {student.birthDate.toString().replace("T00:00:00+00:00", "")}
              </Styled.Detail>
            </Styled.RowWrapper>
            <Styled.RowWrapper>
              <Styled.Label>Miejsce urodzenia</Styled.Label>
              <Styled.Detail>{student.birthCity}</Styled.Detail>
            </Styled.RowWrapper>
            <Styled.RowWrapper>
              <Styled.Label>Imię ojca</Styled.Label>
              <Styled.Detail>{student.fathersFirstName}</Styled.Detail>
            </Styled.RowWrapper>
            <Styled.RowWrapper>
              <Styled.Label>Adres</Styled.Label>
              <Styled.AddressWrapper>
                <Styled.Detail>{student.address.city}</Styled.Detail>
                <Styled.Detail>{student.address.street}</Styled.Detail>
                <Styled.Detail>{student.address.postalCode}</Styled.Detail>
                <Styled.Detail>{student.county}</Styled.Detail>
              </Styled.AddressWrapper>
            </Styled.RowWrapper>
          </Styled.ColumnWrapper>
        </Styled.StudentWrapper>
        <Styled.SchoolWrapper>
          <Styled.Title>Dane szkoły</Styled.Title>
          <Styled.ColumnWrapper>
            <Styled.RowWrapper>
              <Styled.Label>Nazwa szkoły</Styled.Label>
              <Styled.Detail>{student.school.name}</Styled.Detail>
            </Styled.RowWrapper>
            <Styled.RowWrapper>
              <Styled.Label>Klasa</Styled.Label>
              <Styled.Detail>{student.school.class}</Styled.Detail>
            </Styled.RowWrapper>
            <Styled.RowWrapper>
              <Styled.Label>Opiekun ucznia</Styled.Label>
              <Styled.Detail>
                {student.patronName ? student.patronName : "Nie podano"}
              </Styled.Detail>
            </Styled.RowWrapper>
            <Styled.RowWrapper>
              <Styled.Label>Nauczyciel</Styled.Label>
              <Styled.Detail>
                {student.teachersName ? student.teachersName : "Nie podano"}
              </Styled.Detail>
            </Styled.RowWrapper>
            <Styled.RowWrapper>
              <Styled.Label>Adres szkoły</Styled.Label>
              <Styled.AddressWrapper>
                <Styled.Detail>{student.school.address.city}</Styled.Detail>
                <Styled.Detail>{student.school.address.street}</Styled.Detail>
                <Styled.Detail>
                  {student.school.address.postalCode}
                </Styled.Detail>
              </Styled.AddressWrapper>
            </Styled.RowWrapper>
          </Styled.ColumnWrapper>
        </Styled.SchoolWrapper>
      </Styled.MainWrapper>
    </>
  );
};

export default StudentDetails;
