import { SessionStateType } from "./StudentsContext";
import Student from "../../types/Student";

export type BaseAction = {
  type: "LOAD_STUDENTS" | "LOADED_STUDENTS";
  pendingStudents?: Student[] | null;
  acceptedStudents?: Student[] | null;
};

export const studentsReducer = (
  previousState: SessionStateType,
  action: BaseAction
): SessionStateType => {
  switch (action.type) {
    case "LOAD_STUDENTS":
      if (
        action.pendingStudents === undefined ||
        action.pendingStudents === null ||
        action.acceptedStudents === undefined ||
        action.acceptedStudents === null
      ) {
        throw Error(
          "User has to be provided into authReducer when LOGIN action is declared"
        );
      }
      return {
        ...previousState,
        state: "LOAD_STUDENTS",
        pendingStudents: action.pendingStudents,
        acceptedStudents: action.acceptedStudents,
      };
    default:
      return previousState;
  }
};
