import React, { FunctionComponent } from "react";
import { InlineNotification, NotificationKind } from "carbon-components-react";
import NotificationWarningIcon from "../../icons/NotificationWarningIcon";
import NotificationInfoIconWrapper from "../NotificationInfoIconWrapper/NotificationInfoIconWrapper";
import ThemeStyle from "../../styles/ThemeStyles";
import NotificationSuccessIcon from "../../icons/NotificationSuccessIcon";

type Props = {
  kind: NotificationKind;
  title: string;
  subtitle?: string;
};

const Notification: FunctionComponent<Props> = ({ kind, title, subtitle }) => {
  return (
    <InlineNotification
      kind={kind ? kind : "warning"}
      title={title}
      subtitle={subtitle}
      hideCloseButton={true}
    >
      {kind === "success" ? (
        <NotificationSuccessIcon />
      ) : (
        <NotificationWarningIcon />
      )}
      <NotificationInfoIconWrapper
        color={
          kind === "success"
            ? ThemeStyle.colors.notificationColors.success
            : ThemeStyle.colors.notificationColors.warning
        }
      />
    </InlineNotification>
  );
};

export default Notification;
