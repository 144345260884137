import styled from "styled-components";
import { HeaderH3 } from "../../../../styles/BasicStyles";

export const SuccessWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  gap: 10rem;
`;

export const SubHeader = styled.h3`
  ${HeaderH3};
  margin-bottom: 3rem;
`;
