import React, { FunctionComponent } from "react";
import * as Styled from "./CoordinatorPage.styled";
import PendingStudents from "../../components/PendingStudents/PendingStudents";
import AcceptedStudents from "../../components/AcceptedStudents/AcceptedStudents";
import Table from "../../components/Table/Table";
import LogoutButton from "../../components/LogoutButton/LogoutButton";

type Props = {};

const CoordinatorPage: FunctionComponent<Props> = () => {
  const coordinatorTabs: string[] = ["Zaakceptowani uczniowie", "Zgłoszenia"];

  const coordinatorTabsPanels: JSX.Element[] = [
    <AcceptedStudents />,
    <PendingStudents />,
  ];
  return (
    <Styled.CoordinatorPage>
      <Styled.Container>
        <Table tabs={coordinatorTabs} tabsPanels={coordinatorTabsPanels} />
        <LogoutButton />
      </Styled.Container>
    </Styled.CoordinatorPage>
  );
};

export default CoordinatorPage;
