import React, { FunctionComponent, useEffect, useState } from "react";
import { Button, Loading, TextInput } from "carbon-components-react";
import { SubmitHandler, useForm } from "react-hook-form";
import api from "../../services/api";
import { Application } from "../../types/Application";
import Student from "../../types/Student";
import CustomModal from "../CustomModal/CustomModal";
import { ApplicationsContext } from "../../context/ApplicationsContext/ApplicationsContext";
import * as Styled from "./ThirdStage.styled";

type Props = {
  application?: Application | undefined;
  student: Student;
};
type Inputs = {
  id?: number;
  gradeThirdStage: {
    firstGrade?: number;
    comment?: string;
  };
};

const ThirdStage: FunctionComponent<Props> = ({ application, student }) => {
  const [success, setSuccess] = useState(false);
  const [loadingState, setLoadingState] = useState(false);
  const [errMsg, setErrMsg] = useState("sss");
  const [appli, setAppli] = useState<any>(null);
  useEffect(() => {
    setAppli(application);
  }, [application]);

  const { loadApplications } = React.useContext(ApplicationsContext);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<Inputs>({
    mode: "onSubmit",
    reValidateMode: "onChange",
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: false,
  });

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    setSuccess(false);
    setLoadingState(true);
    try {
      if (application) {
        await api.put(application["@id"].replace("api/", "") + "/grade", {
          gradeThirdStage: {
            firstGrade:
              data.gradeThirdStage.firstGrade ||
              data.gradeThirdStage.firstGrade === 0
                ? Number(data.gradeThirdStage.firstGrade)
                : null,
            comment: data.gradeThirdStage.comment ?? null,
          },
        });
      } else if (!appli) {
        await api
          .post("/applications", {
            student: `api/students/${student.id}`,
          })
          .then((r) => {
            return r.data;
          })
          .then((application) => {
            setAppli(application);

            api.put(application["@id"].replace("api/", "") + "/grade", {
              gradeSecondStage: {
                firstGrade: data.gradeThirdStage.firstGrade
                  ? Number(data.gradeThirdStage.firstGrade)
                  : null,
                comment: data.gradeThirdStage.comment ?? null,
              },
            });
          });
      }
      setLoadingState(false);
      setSuccess(true);
      loadApplications();
    } catch (err) {
      if (err) {
        setErrMsg("Błąd połączenia");
      }
    }
  };

  return (
    <Styled.ThirdStage>
      {loadingState && (
        <CustomModal>
          <Loading />
        </CustomModal>
      )}
      <Styled.Title>Etap III</Styled.Title>
      Wpisz jednocześnie oba pola formularza: punkty i komentarz, dopiero
      kliknij zatwierdź. Jeżeli wpiszesz najpeirw punkty i zatwierdzisz, a potem
      komentarz i zatwierdzisz nie wpisując punktów, punkty się wyzerują.
      <Styled.FormWrapper>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Styled.Wrapper>
            <TextInput
              {...register("gradeThirdStage.firstGrade", {
                max: {
                  value: 100,
                  message: "Wprowadź liczbę między 0 a 100",
                },
              })}
              id={"gradeThirdStage.firstGrade"}
              labelText={"Wpisz liczbę punktów"}
              step={0.01}
              disabled={false}
            />
            <p>
              Aktualnie przyznane punkty:{" "}
              {application?.gradeThirdStage?.firstGrade ??
                "Jeszcze nie przyznano"}
            </p>
          </Styled.Wrapper>
          <Styled.Wrapper>
            <TextInput
              {...register("gradeThirdStage.comment", {})}
              id={"gradeThirdStage.comment"}
              labelText={"Komentarz"}
            />
            <p>
              Komantarz:{" "}
              {application?.gradeThirdStage?.comment ??
                "Nie wpisano komentarza"}
            </p>
          </Styled.Wrapper>
          <Button type={"submit"}>Zatwierdź</Button>
        </form>
      </Styled.FormWrapper>
    </Styled.ThirdStage>
  );
};

export default ThirdStage;
