import React, { FunctionComponent, useEffect, useState } from "react";
import * as Styled from "./StudentApplication.styled";
import { Button, Loading, TextInput } from "carbon-components-react";
import { SubmitHandler, useForm } from "react-hook-form";
import api from "../../services/api";
import { HOST } from "../../config";
import { Application } from "../../types/Application";
import Student from "../../types/Student";
import CustomModal from "../CustomModal/CustomModal";
import { OptionsContext } from "../../context/OptionsContext/OptionsContext";
import StudentFiles from "../StudentFiles/StudentFiles";

type Props = {
  student: Student;
  application: Application;
};
type Inputs = {
  gradeFirstStage: number;
};

const API_URL = (): string => {
  if (HOST === "https://dev-api.olimpiadafilozoficzna.edu.pl/api") {
    return HOST?.replace("/api", "/");
  }

  if (HOST === "https://api2.olimpiadafilozoficzna.edu.pl/api") {
    return HOST?.slice(0, -3);
  }

  return "";
};

const StudentApplication: FunctionComponent<Props> = ({ application }) => {
  const [success, setSuccess] = useState(false);
  const [loadingState, setLoadingState] = useState(false);
  const [studentApplication, setStudentApplication] = useState<Application>();
  const [errMsg, setErrMsg] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {
    session: { options },
  } = React.useContext(OptionsContext);

  useEffect(() => {
    setStudentApplication(application);
  }, []);

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    setSuccess(false);
    setLoadingState(true);
    try {
      if (studentApplication) {
        await api.put(
          studentApplication["@id"].replace("api/", "") + "/grade",
          {
            gradeFirstStage: Number(data.gradeFirstStage),
          }
        );
      }
      setLoadingState(false);
      setSuccess(true);
      window.location.reload();
    } catch (err) {
      if (err) {
        setErrMsg("Błąd  połączenia");
      }
    }
  };
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<Inputs>({
    mode: "onSubmit",
    reValidateMode: "onChange",
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: false,
  });
  return (
    <>
      {loadingState && (
        <CustomModal>
          <Loading />
        </CustomModal>
      )}
      {studentApplication &&
        studentApplication.files.length > 0 &&
        studentApplication?.files[0].contentUrl && (
          <>
            <Styled.ApplicationWrapper>
              <Styled.Title>Poniżej możesz pobrać pracę ucznia</Styled.Title>
              <StudentFiles
                application={studentApplication}
                fontColor={"black"}
              />

              <Styled.Title className={"gradeTitle"}>
                {options?.[1].value
                  ? "Praca została już oceniona a wyniki opublikowane, nie możesz ich zmienić"
                  : "              Poniżej możesz ocenić pracę ucznia"}
              </Styled.Title>
              {!options?.[1].value && (
                <Styled.FormWrapper>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <TextInput
                      {...register("gradeFirstStage", {
                        required: true,
                        min: {
                          value: 0,
                          message: "Wprowadź liczbę między 0 a 30",
                        },
                        max: {
                          value: 30,
                          message: "Wprowadź liczbę między 0 a 30",
                        },
                      })}
                      id={"praca"}
                      labelText={
                        "Wpisz liczbę punktów za pracę w zakresie od 0 do 30"
                      }
                    ></TextInput>
                    <Button type={"submit"}>Zatwierdź</Button>
                  </form>
                </Styled.FormWrapper>
              )}
              <Styled.Title className={"gradeTitle"}>
                Aktualnie przyznane punkty:
              </Styled.Title>
              <Styled.Detail>
                {studentApplication.gradeFirstStage !== undefined ||
                studentApplication.gradeFirstStage !== null
                  ? studentApplication.gradeFirstStage
                  : "jeszcze nie przyznano"}
              </Styled.Detail>
            </Styled.ApplicationWrapper>
          </>
        )}
    </>
  );
};

export default StudentApplication;
