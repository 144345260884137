import styled, { css } from "styled-components";
import { BaseDropdown } from "../../styles/BasicStyles";

export const ExpandedRow = styled.div`
  display: grid;
  width: 100%;
  padding: 2rem 0 2rem 0;
  gap: 2rem;
`;

export const Dropdown = styled.div`
  ${BaseDropdown}
`;

export const FormWrapper = styled.div`
  margin-top: 2rem;

  button {
    margin-top: 0.3rem;
  }

  .cds--label {
    color: black;
  }
  .cds--text-input__field-outer-wrapper {
    border: 1px solid grey;
    width: 10%;
  }

  .cds--text-input,
  .cds--text__input,
  .cds--text-input--md {
    color: #000;
  }
`;
