import * as React from "react";
import * as Styled from "./RegisterScreen.styled";
import RegisterForm from "./components/RegisterForm/RegisterForm";
import useRedirect from "../../hooks/useRedirect";

export const RegisterScreen = () => {
  useRedirect();

  return (
    <Styled.RegisterScreen>
      <Styled.Container>
        <Styled.Header>Zgłoś się do udziału w olimpiadzie</Styled.Header>
        <Styled.FormWrapper>
          <Styled.FormInnerWrapper>
            <RegisterForm />
          </Styled.FormInnerWrapper>
        </Styled.FormWrapper>
      </Styled.Container>
    </Styled.RegisterScreen>
  );
};
