import React from "react";
import { OptionsType } from "../../types/Options";

export type SessionStateType = {
  state: "LOAD_OPTIONS" | "LOADED_OPTIONS";
  options: OptionsType | null;
};

export type AuthContextType = {
  dispatch: React.Dispatch<any>;
  session: SessionStateType;
  loadOptions: () => void;
};

export const OptionsContext = React.createContext<AuthContextType>({
  dispatch: () => {},
  loadOptions: () => {},
  session: {
    options: null,
    state: "LOAD_OPTIONS",
  },
});
