import React, { FunctionComponent, useState } from "react";
import * as Styled from "./ResetPassword.styled";
import api from "../../services/api";
import { SubmitHandler, useForm } from "react-hook-form";
import InputText from "../../components/InputText/InputText";
import { Button } from "carbon-components-react";
import { useNavigate } from "react-router-dom";

type Props = {};
type Inputs = {
  email: string;
};

const ResetPassword: FunctionComponent<Props> = (props) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<Inputs>({
    mode: "onSubmit",
    reValidateMode: "onChange",
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: false,
  });
  const [success, setSuccess] = useState(false);
  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    api.post("/reset-password", { email: data.email }).then((r) => {
      setSuccess(true);
    });
  };

  return (
    <Styled.ResetPassword>
      <Styled.Container>
        <Styled.FormWrapper>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Styled.FormInnerWrapper>
              <InputText
                register={register("email", {
                  required: true,
                })}
                helperText={"Wprowadź adres e-mail"}
                id={"email"}
                labelText={"E-mail"}
                placeholder={"j.kowalski@gmail.com"}
                disabled={false}
                invalid={false}
              />
            </Styled.FormInnerWrapper>
            <Button type={"submit"}> Resetuj hasło </Button>
            {success && <p>Sprawdź swojego maila</p>}
          </form>
        </Styled.FormWrapper>
      </Styled.Container>
    </Styled.ResetPassword>
  );
};

export default ResetPassword;
