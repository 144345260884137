import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { AuthProvider } from "./context/AuthProvider";
import { DistrictsProvider } from "./context/DistrictsContext/DistrictsProvider";
import { StudentsProvider } from "./context/StudentsContext/StudentsProvider";
import { ApplicationsProvider } from "./context/ApplicationsContext/ApplicationsProvider";
import { OptionsProvider } from "./context/OptionsContext/OptionsProvider";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <Router>
      <AuthProvider>
        <OptionsProvider>
          <DistrictsProvider>
            <StudentsProvider>
              <ApplicationsProvider>
                <Routes>
                  <Route path={"/*"} element={<App />} />
                </Routes>
              </ApplicationsProvider>
            </StudentsProvider>
          </DistrictsProvider>
        </OptionsProvider>
      </AuthProvider>
    </Router>
  </React.StrictMode>
);
