import React from "react";
import { Application } from "../../types/Application";

export type SessionStateType = {
  state: "LOAD_APPLICATIONS" | "LOADED_APPLICATIONS";
  applications: Application[] | null;
};

export type AuthContextType = {
  dispatch: React.Dispatch<any>;
  session: SessionStateType;
  loadApplications: () => void;
};

export const ApplicationsContext = React.createContext<AuthContextType>({
  dispatch: () => {},
  loadApplications: () => {},
  session: {
    applications: null,
    state: "LOAD_APPLICATIONS",
  },
});
