import styled, { css } from "styled-components";
const { Tabs, TabList, Tab, TabPanels, TabPanel } = require("@carbon/react");

export const TabWrapper = styled(Tab)``;

export const CustomTab = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row-reverse;
`;
export const IndicatorWrapper = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0f62fe;
  margin-left: 1.4rem;
  font-size: 1rem;
`;
