import styled from "styled-components";
import { BasicText } from "../../styles/BasicStyles";
import ThemeStyles from "../../styles/ThemeStyles";
import ThemeStyle from "../../styles/ThemeStyles";

export const FileName = styled.p<{ color?: string }>`
  ${BasicText};
  font-weight: bold;
  line-height: 1.5;
  font-size: 1.3rem;
  color: ${(props) => props.color || ThemeStyle.colors.secondary};
`;
export const FilesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
export const FileWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 3rem;
  border: 1px solid ${ThemeStyles.colors.sections.welcomeSection};
  border-radius: 1rem;
  padding: 1rem;
`;

export const Date = styled.p<{ color?: string }>`
  ${BasicText};
  display: flex;
  gap: 1rem;
  color: ${(props) => props.color || ThemeStyle.colors.secondary};
`;

export const ActualFile = styled.p`
  ${BasicText};
  font-weight: bold;
  color: ${ThemeStyles.colors.notificationColors.success};
`;
