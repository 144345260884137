import React, { FunctionComponent, useEffect, useState } from "react";
import * as Styled from "./AcceptedStudents.styled";
import StudentsTable from "../StudentsTable/StudentsTable";
import { StudentsContext } from "../../context/StudentsContext/StudentsContext";
import { ApplicationsContext } from "../../context/ApplicationsContext/ApplicationsContext";
import { OptionsContext } from "../../context/OptionsContext/OptionsContext";

type Props = {};

const AcceptedStudents: FunctionComponent<Props> = () => {
  const {
    session: { acceptedStudents },
  } = React.useContext(StudentsContext);

  const {
    session: { applications },
    loadApplications,
  } = React.useContext(ApplicationsContext);

  const {
    session: { options },
    loadOptions,
  } = React.useContext(OptionsContext);

  useEffect(() => {
    if (!applications) {
      loadApplications();
    }
  }, [applications]);

  useEffect(() => {
    if (!options) {
      loadOptions();
    }
  }, [options]);

  return (
    <Styled.AcceptedStudents>
      {acceptedStudents && options && applications && (
        <StudentsTable
          students={acceptedStudents}
          applications={applications}
          options={options}
          title={"Wszyscy zaakceptowani uczniowie"}
          description={
            "Poniżej znajdziesz wszystkich uczniów, których uczestnictwo w olimpiadzie zostało" +
            " zaakceptowane, nawet jeżeli nie wgrali pracy lub nie przeszli do drugiego etapu. Każdemu z nich możesz" +
            " wpisać punkty za test, pracę, odpowiedź ustną i dodać komentarz. Nie musisz wpisywać wszystkich" +
            " punktów od razu. Uczniowie poznają swoje punkty dopiero wtedy, gdy opublikuje je KOF."
          }
        />
      )}
    </Styled.AcceptedStudents>
  );
};

export default AcceptedStudents;
