import styled, { css } from "styled-components";
import { mediaDown } from "../../styles/Mixins";

type Props = {
  color: string;
};

export const NotificationIconWrapper = styled.div<Props>`
  position: absolute;
  left: 57vw;
  ${mediaDown(
    "900px",
    css`
      left: 55vw;
    `
  )}

  ${mediaDown(
    "550px",
    css`
      left: 53vw;
    `
  )}

  ${(props: Props) =>
    props.color &&
    css`
      path {
        fill: ${props.color};
      }
    `}
  
  .tooltip {
    display: none;
    position: absolute;
  }

  :hover {
    .tooltip {
      display: flex;
    }
  }
`;
