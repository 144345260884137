import * as React from "react";
import { Button } from "carbon-components-react";
import * as Styled from "./LoginScreen.styled";
import { NavigateFunction, useNavigate } from "react-router-dom";
import ChangingText from "./components/ChangingText/ChangingText";
import OwlSvg from "./components/Owl/OwlSvg";
import LoginForm from "./components/LoginForm/LoginForm";
import Authors from "./components/Authors/Authors";
import useRedirect from "../../hooks/useRedirect";
import api from "../../services/api";
import { useCallback, useEffect, useState } from "react";
import { OptionsType } from "../../types/Options";

export const LoginScreen: React.FC = () => {
  const navigate: NavigateFunction = useNavigate();
  const [stageOneEndsAt, setStageOneEndsAt] = useState<
    undefined | OptionsType[0]
  >();
  useRedirect();

  const getOptions = useCallback(() => {
    api.get("/public/options").then((response) => {
      setStageOneEndsAt(response.data["hydra:member"][0]);
    });
  }, []);

  useEffect(() => {
    if (!stageOneEndsAt) {
      getOptions();
    }
  }, [stageOneEndsAt]);

  const today = new Date();

  return (
    <Styled.LoginScreen>
      <Styled.Container>
        <Styled.TitleWrapper>
          <Styled.Header> Olimpiada filozoficzna </Styled.Header>
          <ChangingText />
        </Styled.TitleWrapper>
        <Styled.FormWrapper>
          <Styled.SubHeader>{"> "}Zaloguj się do serwisu</Styled.SubHeader>{" "}
          <Styled.FormInnerWrapper>
            <LoginForm />
          </Styled.FormInnerWrapper>
        </Styled.FormWrapper>
        <Styled.FormWrapper>
          <Styled.SubHeader>
            {"> "}Zgłoś się do udziału w olimpiadzie
          </Styled.SubHeader>
          <Styled.FormInnerWrapper>
            {stageOneEndsAt && today < new Date(stageOneEndsAt.value) ? (
              <Button
                onClick={() => {
                  navigate("/register");
                }}
              >
                Zgłoś się
              </Button>
            ) : (
              "Zgłoszenia będą możliwe od 3 października"
            )}
          </Styled.FormInnerWrapper>
        </Styled.FormWrapper>
        <Authors />
      </Styled.Container>
      <Styled.SecondContainer>
        <OwlSvg />
      </Styled.SecondContainer>
    </Styled.LoginScreen>
  );
};
