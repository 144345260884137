import React, { FunctionComponent } from "react";
import * as Styled from "./NotificationInfoIconWrapper.styled";

type Props = {
  color: string;
  tooltipText?: string;
};

const NotificationInfoIconWrapper: FunctionComponent<Props> = ({ color }) => {
  return (
    <Styled.NotificationIconWrapper
      color={color}
    ></Styled.NotificationIconWrapper>
  );
};

export default NotificationInfoIconWrapper;
