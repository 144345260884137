import { SessionStateType } from "./AuthContext";
import CurrentUser from "../types/CurrentUser";

export type BaseAction = {
  type: "LOADING" | "LOGIN" | "LOGOUT" | "UPDATE_USER" | "SKIP";
  user?: CurrentUser | null;
};

export const authReducer = (
  previousState: SessionStateType,
  action: BaseAction
): SessionStateType => {
  switch (action.type) {
    case "LOGIN":
      if (action.user === undefined || action.user === null) {
        throw Error(
          "User has to be provided into authReducer when LOGIN action is declared"
        );
      }
      return {
        ...previousState,
        state: "LOGGED_IN",
        user: action.user,
      };
    case "LOGOUT":
      return {
        ...previousState,
        state: "LOGGED_OUT",
        user: null,
      };
    case "LOADING": {
      return {
        ...previousState,
        state: "LOADING",
        user: null,
      };
    }
    default:
      return previousState;
  }
};
