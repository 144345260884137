import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import * as Styled from "./SecondLevelSection.styled";
import Notification from "../../../../components/Notification/Notification";
import api from "../../../../services/api";
import ProgressBar from "../FirstLevelSection/components/ProgressBar";
import { ApplicationsContext } from "../../../../context/ApplicationsContext/ApplicationsContext";
type Props = {};

const SecondLevelSection: FunctionComponent<Props> = (props) => {
  const {
    session: { applications },
    loadApplications,
  } = React.useContext(ApplicationsContext);

  const [arePointsPublished, setArePointsPublished] = useState<
    { value: string } | undefined
  >();

  const [stageTwoThreshold, setStageTwoThreshold] = useState<
    { value: string } | undefined
  >();

  const [grades, setGrades] = useState<{
    firstGrade: number;
    secondGrade: number;
    thirdGrade: number;
  }>();

  const [points, setPoints] = useState<number>();

  const getArePointsPublished = () => {
    api.get("/options").then((r) => {
      setArePointsPublished(r.data["hydra:member"][2].value);
    });
  };

  const getStageTwoThreshold = () => {
    api.get("/options").then((r) => {
      setStageTwoThreshold(r.data["hydra:member"][4]);
    });
  };

  useEffect(() => {
    getArePointsPublished();
    loadApplications();
    getStageTwoThreshold();
  }, []);

  useEffect(() => {
    if (applications && applications.length > 0) {
      setGrades(applications[0].gradeSecondStage);
    }
  }, [applications]);

  useEffect(() => {
    if (grades) {
      setPoints(grades.firstGrade + grades.secondGrade + grades.thirdGrade);
    }
  }, [grades]);

  return (
    <Styled.SecondLevelSection>
      <Styled.Container>
        <Styled.Title>ETAP II</Styled.Title>
        <Notification
          kind={"success"}
          title={"Etap II odbędzie się 18.01.2025 r."}
        />
        <br></br>
        <Styled.Descending>
          Jeżeli zostałeś/aś zakwalifikowany/a do etapu II i wziąłeś w nim
          udział poniżej zobaczysz swoje punkty po ich opublikowaniu. Punkty za
          esej i test zostaną Ci udostępnione przez Twój Komitet Okręgowy - nie
          będą wyświetlane tutaj. Wtedy dowiesz się, czy możesz podejść do
          egzaminu ustnego. Dopiero po zakończeniu rozmów Komitet Główny
          opublikuje wszystkie punkty w systemie poniżej, o czym zostaniesz
          poinformowany/a mailem i będziesz wtedy mógł/mogła wejść i je
          sprawdzić.{" "}
        </Styled.Descending>
        {arePointsPublished && (
          <>
            <Styled.Title style={{ marginTop: "5rem" }}>
              Wyniki zostały opublikowane!
            </Styled.Title>
            <Styled.Descending>
              Powinni Państwo widzieć trzy paski z liczbami punktów. Jeżeli
              zamiast liczby swoich punktów widzą Państwo 'undefined' - proszę
              odświeżyć stronę. Jeżeli zamiast jakiegoś paska widzą Państwo
              'Brak punktów', proszę kontaktować się ze swoim Komitetem
              okręgowym, bo znaczy to, że nie wpisali Państwu punktów.
            </Styled.Descending>
          </>
        )}
        {arePointsPublished ? (
          grades?.firstGrade || grades?.firstGrade === 0 ? (
            <ProgressBar
              value={`${grades?.firstGrade}`}
              isWinner={true}
              maxValue={"40"}
              title={"Twój wynik testu:"}
              descending={""}
            />
          ) : (
            <Styled.Descending>Brak punktów</Styled.Descending>
          )
        ) : null}
        {arePointsPublished ? (
          grades?.secondGrade || grades?.secondGrade === 0 ? (
            <ProgressBar
              value={`${grades?.secondGrade}`}
              isWinner={true}
              maxValue={"50"}
              title={"Twój wynik pracy:"}
              descending={""}
            />
          ) : (
            <Styled.Descending>Brak punktów</Styled.Descending>
          )
        ) : null}
        {arePointsPublished ? (
          grades?.thirdGrade || grades?.thirdGrade === 0 ? (
            <ProgressBar
              value={`${grades?.thirdGrade}`}
              isWinner={true}
              maxValue={"30"}
              title={"Twój wynik rozmowy:"}
              descending={""}
            />
          ) : (
            <Styled.Descending>Brak punktów</Styled.Descending>
          )
        ) : null}

        <Styled.Title style={{ marginTop: "5rem" }}>
          {arePointsPublished &&
          grades &&
          stageTwoThreshold &&
          (points || points === 0) ? (
            <ProgressBar
              value={`${points}`}
              isWinner={true}
              maxValue={`${120}`}
              title={"Twoja łączna liczba punktów: "}
              descending={""}
            />
          ) : null}
        </Styled.Title>
        <Styled.Title style={{ marginTop: "5rem" }}>
          {arePointsPublished && stageTwoThreshold ? (
            <div>
              Aby sprawdzić, czy zostałeś/aś zakwalifikowany/a do finału
              olimpiady filozoficznej, sprawdź stronę https://ptfilozofia.pl/
              <br></br>
              Pamiętaj, że aby zakwalifikować się do finału olimpiady, musisz
              mieć nie tylko więcej punktów niż ustalony próg, ale również mieć
              wymaganą liczbę punktów z poszczególnych etapów etapu II.
            </div>
          ) : null}
        </Styled.Title>
      </Styled.Container>
    </Styled.SecondLevelSection>
  );
};

export default SecondLevelSection;
