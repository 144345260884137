import React from "react";
import MainLayout from "./MainLayout/MainLayout";
import { Route, Routes } from "react-router-dom";
import { LoginScreen } from "./pages/LoginPage/LoginScreen";
import { RegisterScreen } from "./pages/RegisterPage/RegisterScreen";
import StudentPage from "./pages/StudentPage/StudentPage";
import CoordinatorPage from "./pages/CoordinatorPage/CoordinatorPage";
import AdminPage from "./pages/AdminPage/AdminPage";
import RequireAuth from "./context/RequireAuth";
import ResetPassword from "./pages/ResetPasswordPage/ResetPassword";
import NewPassword from "./pages/NewPasswordPage/NewPassword";

function App() {
  return (
    <Routes>
      <Route path={"/"} element={<MainLayout />}>
        <Route path={"/"} element={<LoginScreen />} />
        <Route path={"register"} element={<RegisterScreen />} />
        <Route path={"reset-password"} element={<ResetPassword />} />
        <Route path={"new-password"} element={<NewPassword />} />
        <Route element={<RequireAuth allowedRole={"student"} />}>
          <Route path={"student"} element={<StudentPage />} />
        </Route>
        <Route element={<RequireAuth allowedRole={"coordinator"} />}>
          <Route path={"coordinator"} element={<CoordinatorPage />} />
        </Route>
        <Route element={<RequireAuth allowedRole={"admin"} />}>
          <Route path={"admin"} element={<AdminPage />} />
        </Route>
      </Route>
    </Routes>
  );
}

export default App;
