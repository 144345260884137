import React, { FunctionComponent, useEffect, useState } from "react";
import { TextScramble } from "@a7sc11u/scramble";

type Props = {};
type ScrambleText = string;
type ScrambleTexts = ScrambleText[];

const ChangingText: FunctionComponent<Props> = (props) => {
  const texts: ScrambleTexts = [
    "> arche",
    "> nous",
    "> arete",
    "> apeiron",
    "> esse",
    "> perceptio",
    "> daimonion",
    "> Dasein",
  ];

  const colors = [
    "#78a9ff",
    "#08bdba",
    "#be95ff",
    "#ff7eb6",
    "#ff8389",
    "#ff832b",
    "#be95ff",
    "#3ddbd9",
  ];

  const [scrambleText, setScrambleText] = useState<string>("arche");
  const [textColor, setTextColor] = useState<string>("red");

  useEffect(() => {
    texts.map((t, idx) =>
      setTimeout(() => {
        setScrambleText(t);
      }, 500 * idx)
    );

    colors.map((c, idx) =>
      setTimeout(() => {
        setTextColor(c);
      }, 500 * idx)
    );
  }, []);

  return (
    <TextScramble
      style={{
        color: textColor,
        textAlign: "left",
        marginBottom: "5rem",
        fontSize: "4rem",
      }}
      as="div"
      play={true}
      speed={1}
      scramble={8}
      step={1}
      stepInterval={1}
      seed={3}
      seedInterval={10}
      text={scrambleText}
      className={"scrambleText"}
    />
  );
};

export default ChangingText;
