import { Application } from "../../types/Application";
import { OptionsType } from "../../types/Options";
import { SessionStateType } from "./OptionsContext";

export type BaseAction = {
  type: "LOAD_OPTIONS" | "LOADED_OPTIONS";
  options?: OptionsType | null;
};

export const optionsReducer = (
  previousState: SessionStateType,
  action: BaseAction
): SessionStateType => {
  switch (action.type) {
    case "LOAD_OPTIONS":
      if (action.options === undefined || action.options === null) {
        throw Error(
          "User has to be provided into authReducer when LOGIN action is declared"
        );
      }
      return {
        ...previousState,
        state: "LOADED_OPTIONS",
        options: action.options,
      };
    default:
      return previousState;
  }
};
