import React, { FunctionComponent } from "react";

type Props = {};

const NotificationWarningIcon: FunctionComponent<Props> = (props) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="16" height="16" />
      <path
        d="M6 14.5C5.90129 14.4995 5.80493 14.4698 5.72307 14.4146C5.64121 14.3595 5.57751 14.2813 5.54 14.19L3.165 8.5H1V7.5H3.5C3.59871 7.50049 3.69507 7.53019 3.77693 7.58536C3.85879 7.64053 3.92249 7.71869 3.96 7.81L6 12.64L10.03 1.825C10.0658 1.7293 10.13 1.64685 10.214 1.58874C10.298 1.53064 10.3978 1.49967 10.5 1.5C10.6031 1.5018 10.7031 1.53544 10.7864 1.59631C10.8696 1.65717 10.932 1.74229 10.965 1.84L12.86 7.5H15V8.5H12.5C12.3952 8.50027 12.2929 8.46759 12.2077 8.40658C12.1224 8.34557 12.0585 8.25931 12.025 8.16L10.5 3.5L6.47 14.175C6.43424 14.2707 6.37003 14.3531 6.286 14.4113C6.20197 14.4694 6.10216 14.5003 6 14.5Z"
        fill="#F1C21B"
      />
    </svg>
  );
};

export default NotificationWarningIcon;
