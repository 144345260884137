import React from "react";
import CurrentUser from "../types/CurrentUser";

export type SessionStateType = {
  user: CurrentUser | null;
  state: "LOGGED_IN" | "LOGGED_OUT" | "LOADING";
};

export type AuthContextType = {
  dispatch: React.Dispatch<any>;
  session: SessionStateType;
  loadUser: () => void;
};

export const AuthContext = React.createContext<AuthContextType>({
  dispatch: () => {},
  loadUser: () => {},
  session: {
    user: null,
    state: "LOGGED_OUT",
  },
});
