import React, { FunctionComponent } from "react";
import WelcomeSection from "./sections/WelcomeSection/WelcomeSection";
import FirstLevelSection from "./sections/FirstLevelSection/FirstLevelSection";
import SecondLevelSection from "./sections/SecondLevelSection/SecondLevelSection";
import ThirdLevelSection from "./sections/ThirdLevelSection/ThirdLevelSection";

type Props = {};

const StudentPage: FunctionComponent<Props> = (props) => {
  return (
    <>
      <WelcomeSection />
      <FirstLevelSection />
      <SecondLevelSection />
      <ThirdLevelSection />
    </>
  );
};

export default StudentPage;
