import React, { useCallback, useEffect, useMemo } from "react";
import { AxiosError } from "axios";
import { studentsReducer } from "./StudentsReducer";
import api from "../../services/api";
import { StudentsContext } from "./StudentsContext";
import Student from "../../types/Student";
import { Simulate } from "react-dom/test-utils";
import load = Simulate.load;
import RequireAuth from "../RequireAuth";
import { AuthContext } from "../AuthContext";

type Props = {
  children?: React.ReactNode;
};
export const StudentsProvider: React.FC<Props> = ({ children }) => {
  const [session, dispatch] = React.useReducer(studentsReducer, {
    pendingStudents: null,
    acceptedStudents: null,
    state: "LOAD_STUDENTS",
  });

  const {
    session: { user },
  } = React.useContext(AuthContext);

  const loadStudents = () => {
    return api
      .get("/students")
      .then((r) => {
        dispatch({
          type: "LOAD_STUDENTS",
          pendingStudents: r.data["hydra:member"]
            .map((s: Student) => ({
              ...s,
              id: s.id.toString(),
            }))
            .filter((s: Student) => {
              return s.state === "pending";
            }),
          acceptedStudents: r.data["hydra:member"]
            .map((s: Student) => ({
              ...s,
              id: s.id.toString(),
            }))
            .filter((s: Student) => {
              return s.state === "accepted";
            }),
        });
      })
      .catch((error: AxiosError) => {
        console.log("problem with students");
        return Promise.reject(error);
      });
  };

  useEffect(() => {
    if (user) {
      loadStudents();
    }
  }, [user]);

  return (
    <StudentsContext.Provider value={{ session, dispatch, loadStudents }}>
      {children}
    </StudentsContext.Provider>
  );
};
