import React, { FC, ReactNode } from "react";
import { ThemeProvider } from "styled-components";
import ThemeStyle from "../styles/ThemeStyles";
import GlobalStyle from "../styles/GlobalStyles";
import "./ApplyCarbonStyling.scss";
import { Outlet } from "react-router-dom";

const MainLayout: FC = () => {
  return (
    <ThemeProvider theme={ThemeStyle}>
      <GlobalStyle />
      <main id={"modalPortal"}>
        <Outlet />
      </main>
    </ThemeProvider>
  );
};

export default MainLayout;
