import React, { FunctionComponent, useState } from "react";
import * as Styled from "./NewPassword.styled";
import { SubmitHandler, useForm } from "react-hook-form";
import api from "../../services/api";
import InputText from "../../components/InputText/InputText";
import { Button } from "carbon-components-react";
import { useNavigate } from "react-router-dom";
import useQuery from "../../hooks/useQuery";

type Props = {};
type Inputs = {
  token: string;
  newPassword: string;
};
const NewPassword: FunctionComponent<Props> = (props) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<Inputs>({
    mode: "onSubmit",
    reValidateMode: "onChange",
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: false,
  });
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();
  const token = useQuery("token");

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    api
      .post("/change-password", {
        newPassword: data.newPassword,
        token: token,
      })
      .then((r) => {
        setSuccess(true);
      });
  };

  return (
    <Styled.NewPassword>
      <Styled.Container>
        <Styled.FormWrapper>
          <Styled.SubHeader>Wprowadź nowe hasło</Styled.SubHeader>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Styled.FormInnerWrapper>
              <InputText
                register={register("newPassword", {
                  required: true,
                  pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/,
                })}
                errors={
                  errors.newPassword &&
                  "Wprowadź hasło w formcie: co najmniej jedna mała litera, jedna duża i jedna cyfra, min. 6 znaków, maks. 20 znaków"
                }
                helperText={"Wprowadź noew hasło"}
                id={"password"}
                labelText={"Nowe hasło"}
                type={"password"}
                placeholder={""}
                disabled={false}
                invalid={false}
              />
            </Styled.FormInnerWrapper>
            <Button type={"submit"}> Zmień hasło </Button>
          </form>
          {success && (
            <>
              <Styled.SubHeader>
                Twoje hasło zostało zmienione, możesz się teraz zalogować
              </Styled.SubHeader>
              <Button
                onClick={() => {
                  navigate("/");
                }}
              >
                Przejdź do logowania
              </Button>
            </>
          )}
        </Styled.FormWrapper>
      </Styled.Container>
    </Styled.NewPassword>
  );
};

export default NewPassword;
