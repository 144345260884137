import React, { FunctionComponent, useEffect, useState } from "react";
import * as Styled from "./ExpandedRow.styled";
import Student from "../../types/Student";
import { Button, Loading } from "carbon-components-react";
import api from "../../services/api";
import { Application } from "../../types/Application";
import { AuthContext } from "../../context/AuthContext";
import CustomModal from "../CustomModal/CustomModal";
import StudentDetails from "./StudentDetails";
import StudentApplication from "./StudentApplication";
import SecondStage from "../SecondStage/SecondStage";
import ThirdStage from "../ThirdStage/ThirdStage";
import { OptionsType } from "../../types/Options";

export type Methods = "acceptStudent";

type Props = {
  student: Student;
  application?: Application | undefined;
  options?: OptionsType;
};

const ExpandedRow: FunctionComponent<Props> = ({
  student,
  application,
  options,
}) => {
  const {
    session: { user },
  } = React.useContext(AuthContext);
  const [success, setSuccess] = useState(false);
  const [loadingState, setLoadingState] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const acceptStudent = () => {
    setLoadingState(true);
    api.put(`/students/${student.id}/accept`).then(() => {
      setLoadingState(false);
      setSuccess(true);
    });
  };

  const onDeleteUser = () => {
    setIsModalOpen(true);
  };

  const deleteUser = () => {
    setLoadingState(true);
    api.delete(`/students/${student.id}`).then(() => {
      setLoadingState(false);
      setSuccess(true);
    });
  };

  const reloadOnSuccess = () => {
    if (success) {
      window.location.reload();
    }
  };

  useEffect(() => {
    reloadOnSuccess();
  }, [success]);

  const onCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      {loadingState && (
        <CustomModal>
          <Loading />
        </CustomModal>
      )}
      <Styled.ExpandedRow>
        {isModalOpen && (
          <CustomModal
            heading={
              "Czy na pewno chcesz usunąć tego użytkownika? Tej akcji nie można cofnąć i będzie się on musiał zarejestrować ponownie i zostać zaakceptowanym, aby wziąć udział w olimpiadzie. Jeżeli pierwszy etap już się zakończył, nie będzie mógł on wgrać swojej pracy"
            }
            label={""}
            primaryButtonText={"Usuń"}
            secondaryButtonText={"Anuluj"}
            onRequestCloseFunction={onCloseModal}
            primaryButtonFunction={deleteUser}
            secondaryButtonFunction={onCloseModal}
          />
        )}
        <ThirdStage application={application} student={student} />
        <SecondStage
          application={application}
          student={student}
          options={options}
        />
        {application && (
          <StudentApplication student={student} application={application} />
        )}
        <StudentDetails student={student} />
        {student.state === "pending" && (
          <Button onClick={acceptStudent}>Zaakceptuj zgłoszenie</Button>
        )}
        {user?.type === "admin" && student.state === "pending" && (
          <Button
            onClick={() => {
              onDeleteUser();
            }}
          >
            Usuń użytkownika
          </Button>
        )}
      </Styled.ExpandedRow>
    </>
  );
};

export default ExpandedRow;
