import React, { FunctionComponent, useEffect, useState } from "react";
import Student from "../../../../../types/Student";
import * as Styled from "../../../../RegisterPage/RegisterScreen.styled";
import { Button, Checkbox, Loading } from "carbon-components-react";
import InputText from "../../../../../components/InputText/InputText";
import { SubmitHandler, useForm } from "react-hook-form";
import api from "../../../../../services/api";

type Props = {
  student: Student;
};
type Inputs = Student;

const ChangeData: FunctionComponent<Props> = ({ student }) => {
  const [success, setSuccess] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [loadingState, setLoadingState] = useState(false);
  const [userBirthDate, setUserBirthDate] = useState();
  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    setLoadingState(true);
    console.log(data);
    try {
      await api.put(`/students/${student.id}`, {
        ...data,
      });

      setSuccess(true);
      setLoadingState(false);
    } catch (err) {
      if (err) {
        setErrMsg("Błąd połączenia");
      }
    }
  };

  const checkIsUserLessThenSixteen = (value: string) => {
    let today = new Date();
    let birthDate = new Date(value);
    let age = today.getFullYear() - birthDate.getFullYear();
    let m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age < 16;
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: student,
  });
  useEffect(() => {
    console.log(student);
  }, [student]);
  return (
    <div>
      {student ? (
        <Styled.RegisterScreen>
          <Styled.Container>
            {success ? (
              <></>
            ) : (
              <>
                {" "}
                <Styled.Header>
                  Pamiętaj, że po edycji danych Twoje zgłoszenie będzie musiało
                  zostać ponownie zaakceptowane!
                </Styled.Header>
                <Styled.FormWrapper>
                  <Styled.FormInnerWrapper>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <Styled.SubHeader>Twoje dane</Styled.SubHeader>
                      <InputText
                        register={register("firstName", {
                          required: true,
                        })}
                        helperText={"Wprowadź imię"}
                        id={"firstname"}
                        labelText={"Imię"}
                        placeholder={student.firstName}
                        disabled={false}
                        defaultValue={student.firstName}
                        invalid={false}
                        errors={
                          errors.firstName && (
                            <Styled.ErrorMessage>
                              Wprowadź imię
                            </Styled.ErrorMessage>
                          )
                        }
                      />
                      <InputText
                        register={register("lastName", {
                          required: true,
                        })}
                        helperText={"Wprowadź nazwisko"}
                        id={"lastname"}
                        labelText={"Nazwisko"}
                        placeholder={student.lastName}
                        defaultValue={student.lastName}
                        disabled={false}
                        invalid={false}
                        errors={
                          errors.lastName && (
                            <Styled.ErrorMessage>
                              Wprowadź nazwisko
                            </Styled.ErrorMessage>
                          )
                        }
                      />
                      <InputText
                        register={register("email", {
                          required: true,
                          pattern: /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i,
                        })}
                        helperText={"Wprowadź adres e-mail"}
                        id={"email"}
                        labelText={"E-mail"}
                        placeholder={student.email}
                        defaultValue={student.email}
                        disabled={false}
                        invalid={false}
                        errors={
                          errors.email && (
                            <Styled.ErrorMessage>
                              Wprowadź poprawny adres e-mail
                            </Styled.ErrorMessage>
                          )
                        }
                      />

                      <InputText
                        register={register("phoneNumber", {
                          required: true,
                        })}
                        helperText={"Wprowadź numer telefonu"}
                        id={"phone"}
                        labelText={"Numer telefonu"}
                        placeholder={student.phoneNumber}
                        defaultValue={student.phoneNumber}
                        disabled={false}
                        invalid={false}
                        errors={
                          errors.phoneNumber && (
                            <Styled.ErrorMessage>
                              Wprowadź numer telefonu
                            </Styled.ErrorMessage>
                          )
                        }
                      />
                      <InputText
                        register={register("fathersFirstName", {
                          required: true,
                        })}
                        helperText={"Wprowadź imię ojca"}
                        id={"fathersfirstname"}
                        labelText={"Imię ojca"}
                        placeholder={student.fathersFirstName}
                        defaultValue={student.fathersFirstName}
                        disabled={false}
                        invalid={false}
                        errors={
                          errors.fathersFirstName && (
                            <Styled.ErrorMessage>
                              Wprowadź imię ojca
                            </Styled.ErrorMessage>
                          )
                        }
                      />
                      <InputText
                        register={register("birthCity", {
                          required: true,
                        })}
                        helperText={"Wprowadź miejsce urodzenia"}
                        id={"birthcity"}
                        labelText={"Miejsce urodzenia"}
                        placeholder={student.birthCity}
                        defaultValue={student.birthCity}
                        disabled={false}
                        invalid={false}
                        errors={
                          errors.birthCity && (
                            <Styled.ErrorMessage>
                              Wprowadź miejsce urodzenia
                            </Styled.ErrorMessage>
                          )
                        }
                      />
                      <InputText
                        register={register("birthDate", {
                          required: true,
                          validate: {
                            positive: (value) => new Date(value) < new Date(),
                          },
                          onChange: (e) => {
                            setUserBirthDate(e.target.value);
                          },
                        })}
                        type={"date"}
                        helperText={`Obecnie wybrana data urodzenia: ${
                          student.birthDate.toString().split("T")[0]
                        }`}
                        labelText={"Data urodzenia"}
                        id={"dateofbirth"}
                        placeholder={""}
                        defaultValue={`${
                          student.birthDate.toString().split("T")[0]
                        }`}
                        disabled={false}
                        invalid={false}
                        errors={
                          errors.birthDate && (
                            <Styled.ErrorMessage>
                              Wprowadź poprawną datę
                            </Styled.ErrorMessage>
                          )
                        }
                      />

                      <InputText
                        register={register("teachersName", {
                          required: false,
                        })}
                        helperText={"Wprowadź imię i nazwisko nauczyciela"}
                        id={"techersname"}
                        labelText={"Imię i nazwisko nauczyciela (opcjonalnie)"}
                        placeholder={student.teachersName}
                        defaultValue={student.teachersName}
                        disabled={false}
                        invalid={false}
                      />
                      <InputText
                        register={register("patronName", {
                          required: false,
                        })}
                        helperText={"Wprowadź imię i nazwisko opiekuna"}
                        id={"patrons name"}
                        labelText={"Imię i nazwisko opiekuna (opcjonalnie)"}
                        placeholder={student.patronName}
                        defaultValue={student.patronName}
                        disabled={false}
                        invalid={false}
                      />
                      <Styled.SubHeader>Adres</Styled.SubHeader>
                      <InputText
                        register={register("address.street", {
                          required: true,
                        })}
                        helperText={"Wprowadź ulicę i numer"}
                        id={"street"}
                        labelText={"Ulica i numer"}
                        placeholder={student.address.street}
                        defaultValue={student.address.street}
                        disabled={false}
                        invalid={false}
                        errors={
                          errors.address?.street && (
                            <Styled.ErrorMessage>
                              Wprowadź ulicę
                            </Styled.ErrorMessage>
                          )
                        }
                      />
                      <InputText
                        register={register("address.city", {
                          required: true,
                        })}
                        helperText={"Wprowadź miasto"}
                        id={"city"}
                        labelText={"Miasto"}
                        placeholder={student.address.city}
                        defaultValue={student.address.city}
                        disabled={false}
                        invalid={false}
                        errors={
                          errors.address?.city && (
                            <Styled.ErrorMessage>
                              Wprowadź miasto
                            </Styled.ErrorMessage>
                          )
                        }
                      />
                      <InputText
                        register={register("county", {
                          required: true,
                        })}
                        helperText={"Wprowadź powiat"}
                        id={"county"}
                        labelText={"Powiat"}
                        placeholder={student.county}
                        defaultValue={student.county}
                        disabled={false}
                        invalid={false}
                        errors={
                          errors.county && (
                            <Styled.ErrorMessage>
                              Wprowadź powiat
                            </Styled.ErrorMessage>
                          )
                        }
                      />
                      <InputText
                        register={register("address.postalCode", {
                          required: true,
                          pattern: /^[0-9]{2}-[0-9]{3}$/,
                        })}
                        helperText={"Wprowadź kod pocztowy"}
                        id={"postal-code"}
                        labelText={"Kod pocztowy"}
                        placeholder={student.address.postalCode}
                        defaultValue={student.address.postalCode}
                        disabled={false}
                        invalid={false}
                        errors={
                          errors.address?.postalCode && (
                            <Styled.ErrorMessage>
                              Wprowadź kod pocztowy w formacie 00-000
                            </Styled.ErrorMessage>
                          )
                        }
                      />

                      <Styled.SubHeader style={{ marginTop: "3rem" }}>
                        Dane szkoły
                      </Styled.SubHeader>
                      <InputText
                        register={register("school.name", {
                          required: true,
                        })}
                        helperText={"Wprowadź nazwę szkoły"}
                        id={"schoolname"}
                        labelText={"Nazwa szkoły"}
                        placeholder={student.school.name}
                        defaultValue={student.school.name}
                        disabled={false}
                        invalid={false}
                        errors={
                          errors.school?.name && (
                            <Styled.ErrorMessage>
                              Wprowadź nazwę szkoły
                            </Styled.ErrorMessage>
                          )
                        }
                      />
                      <InputText
                        register={register("school.address.city", {
                          required: true,
                        })}
                        helperText={"Wprowdź miasto"}
                        id={"schoolcity"}
                        labelText={"Miasto"}
                        placeholder={student.school.address.city}
                        defaultValue={student.school.address.city}
                        disabled={false}
                        invalid={false}
                        errors={
                          errors.school?.address?.city && (
                            <Styled.ErrorMessage>
                              Wprowadź miasto szkoły
                            </Styled.ErrorMessage>
                          )
                        }
                      />
                      <InputText
                        register={register("school.address.street", {
                          required: true,
                        })}
                        helperText={"Wprowadź ulicę i numer szkoły"}
                        id={"adressstreet"}
                        labelText={"Ulica"}
                        placeholder={student.school.address.street}
                        defaultValue={student.school.address.street}
                        disabled={false}
                        invalid={false}
                        errors={
                          errors.school?.address?.street && (
                            <Styled.ErrorMessage>
                              Wprowadź ulicę i numer szkoły
                            </Styled.ErrorMessage>
                          )
                        }
                      />
                      <InputText
                        register={register("school.address.postalCode", {
                          required: true,
                          pattern: /^[0-9]{2}-[0-9]{3}$/,
                        })}
                        helperText={"Wprowadź kod pocztowy szkoły"}
                        id={"schoolpostalcode"}
                        labelText={"Kod pocztowy szkoły"}
                        placeholder={student.school.address.postalCode}
                        defaultValue={student.school.address.postalCode}
                        disabled={false}
                        invalid={false}
                        errors={
                          errors.school?.address?.postalCode && (
                            <Styled.ErrorMessage>
                              Wprowadź kod pocztowy szkoły w formacie 00-000
                            </Styled.ErrorMessage>
                          )
                        }
                      />
                      <InputText
                        register={register("school.class", {
                          required: true,
                        })}
                        helperText={"Wprowadź numer klasy"}
                        id={"schoolclass"}
                        labelText={"Numer klasy"}
                        placeholder={student.school.class}
                        defaultValue={student.school.class}
                        disabled={false}
                        invalid={false}
                        errors={
                          errors.school?.class && (
                            <Styled.ErrorMessage>
                              Wprowadź numer klasy
                            </Styled.ErrorMessage>
                          )
                        }
                      />
                      <Checkbox
                        id={"zgoda1"}
                        labelText={
                          "Oświadczam, że powyższe dane są zgodne ze stanem faktycznym."
                        }
                        required={true}
                      />
                      <Checkbox
                        id={"zgoda3"}
                        labelText={
                          "Oświadczam, że zapoznałem/am się z Klauzulą informacyjną o przetwarzaniu danych osobowych."
                        }
                        required={true}
                      />
                      <br></br>
                      {userBirthDate ? (
                        checkIsUserLessThenSixteen(userBirthDate) ? (
                          <Checkbox
                            id={"zgoda2"}
                            labelText={
                              "Jako opiekun prawny oświadczam, że powyższe dane są zgodne ze stanem faktycznym."
                            }
                            required={true}
                          />
                        ) : (
                          ""
                        )
                      ) : (
                        ""
                      )}
                      <Button type={"submit"}>Zmień dane</Button>
                      {loadingState && (
                        <Loading
                          description="Active loading indicator"
                          withOverlay={false}
                        />
                      )}
                      {errMsg && (
                        <Styled.ErrorMessage>{errMsg}</Styled.ErrorMessage>
                      )}
                      <Styled.Text>
                        XXXV OLIMPIADA FILOZOFICZNA KLAUZULA INFORMACYJNA O
                        PRZETWARZANIU DANYCH OSOBOWYCH Na podstawie art. 13
                        ust.1 i 2 Rozporządzenia Parlamentu Europejskiego i Rady
                        (UE) 2016/678 z dnia 27 kwietnia 2016 r. w sprawie
                        ochrony osób fizycznych w związku z przetwarzaniem
                        danych osobowych i w sprawie swobodnego przepływu tych
                        danych oraz uchylenia dyrektywy 95/46/WE (ogólne
                        rozporządzenie o ochronie danych osobowych) – zwanego
                        dalej RODO, informujemy, iż : 1) Administratorem
                        Pani/Pana danych osobowych jest: Polskie Towarzystwo
                        Filozoficzne z siedzibą w Warszawie (ul. Nowy Świat 72
                        p.160, 00-330 Warszawa) 2) Administrator danych
                        osobowych – Polskie Towarzystwo Filozoficzne -
                        przetwarza Pani/Pana dane osobowe na podstawie art. 6
                        ust. 1 lit a. RODO oraz Rozporządzenia Ministra Edukacji
                        Narodowej i Sportu z dnia 29 stycznia 2002 r. w sprawie
                        organizacji oraz sposobu przeprowadzania konkursów,
                        turniejów i olimpiad. 3) Pani/Pana dane osobowe
                        przetwarzane będą w celu/celach: a) realizacji zadań
                        związanych z przeprowadzeniem przedmiotowej olimpiady
                        szkolnej „Olimpiada Filozoficzna”. Podstawą prawną,
                        zgodnie z którą realizowana jest Olimpiada Filozoficzna,
                        jest Rozporządzenie Ministra Edukacji Narodowej i Sportu
                        z dnia 29 stycznia 2002 r. w sprawie organizacji oraz
                        sposobu przeprowadzania konkursów, turniejów i olimpiad
                        (Dz. U. z 2002 r., nr 13, poz. 125, z późn. zm.). 4) W
                        związku z przetwarzaniem danych w celach o których mowa
                        w pkt 3 odbiorcą Pani/Pana danych osobowych będą
                        instytucje i urzędy wskazane w tym Rozporządzeniu oraz
                        po uzyskaniu Pani/Pana zgody instytucje współpracujące z
                        Polskim Towarzystwem Filozoficznym i KG OF w celu
                        propagowania Olimpiady Filozoficznej. 5) Pani/Pana dane
                        osobowe będą przechowywane do czasu ustawowo określonego
                        w Rozporządzeniu Ministra Edukacji Narodowej i Sportu z
                        dnia 29 stycznia 2002 r. w sprawie organizacji oraz
                        sposobu przeprowadzania konkursów, turniejów i olimpiad
                        (Dz. U. z 2002 r., nr 13, poz. 125, z późn. zm.). 6) W
                        związku z przetwarzaniem danych osobowych pełnoletniemu
                        uczestnikowi Olimpiady oraz rodzicom lub prawnym
                        opiekunom uczestnika nieletniego przysługuje prawo
                        dostępu do treści danych uczestnika, do ich
                        sprostowania, usunięcia, ograniczenia ich przetwarzania
                        i przenoszenia, a także prawo do wniesienia sprzeciwu,
                        oraz inne uprawnienia w tym zakresie wynikające z
                        obowiązujących przepisów prawa. 7) W przypadku gdy
                        przetwarzanie danych osobowych odbywa się na podstawie
                        art. 6 ust. 1 lit a) RODO, czyli zgody na przetwarzanie
                        danych osobowych, pełnoletniemu uczestnikowi Olimpiady
                        oraz rodzicom lub prawnym opiekunom uczestnika
                        nieletniego przysługuje prawo do cofnięcia tej zgody w
                        dowolnym momencie, bez wpływu na zgodność przetwarzania,
                        którego dokonano na podstawie zgody przed jej
                        cofnięciem, z obowiązującym prawem. 8) W przypadku
                        powzięcia informacji o niezgodnym z prawem przetwarzaniu
                        danych osobowych w Polskim Towarzystwie Filozoficznym
                        przysługuje Pani/Panu prawo wniesienia skargi do organu
                        nadzorczego właściwego w sprawach ochrony danych
                        osobowych. 9) Podanie przez Panią/Pana danych osobowych
                        ma charakter dobrowolny, ale jest warunkiem uczestnictwa
                        w Olimpiadzie Filozoficznej. 10) Pani/Pana dane nie będą
                        przetwarzane w sposób zautomatyzowany i nie będą
                        profilowane.
                      </Styled.Text>
                    </form>
                  </Styled.FormInnerWrapper>
                </Styled.FormWrapper>
              </>
            )}
          </Styled.Container>
        </Styled.RegisterScreen>
      ) : (
        ""
      )}
    </div>
  );
};

export default ChangeData;
