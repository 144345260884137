import { useLocation, Navigate, Outlet } from "react-router-dom";
import React from "react";
import { AuthContext } from "./AuthContext";
type Props = {
  allowedRole: string;
};

const RequireAuth: React.FC<Props> = ({ allowedRole }) => {
  const {
    session: { user, state },
  } = React.useContext(AuthContext);

  const location = useLocation();

  return state === "LOADING" ? (
    <>Loading....</>
  ) : !user ? (
    <Navigate to={"/"} state={{ from: location }} replace />
  ) : user.type === allowedRole ? (
    <Outlet />
  ) : (
    <Navigate to={"/unauthorized"} state={{ from: location }} replace />
  );
};

export default RequireAuth;
