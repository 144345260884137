import { Application } from "../../types/Application";
import { SessionStateType } from "./ApplicationsContext";

export type BaseAction = {
  type: "LOAD_APPLICATIONS" | "LOADED_APPLICATIONS";
  applications?: Application[] | null;
};

export const applicationsReducer = (
  previousState: SessionStateType,
  action: BaseAction
): SessionStateType => {
  switch (action.type) {
    case "LOAD_APPLICATIONS":
      if (action.applications === undefined || action.applications === null) {
        throw Error(
          "User has to be provided into authReducer when LOGIN action is declared"
        );
      }
      return {
        ...previousState,
        state: "LOADED_APPLICATIONS",
        applications: action.applications,
      };
    default:
      return previousState;
  }
};
