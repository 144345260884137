import React, { FunctionComponent, useState } from "react";
import * as Styled from "./Districts.styled";
import DistrictsTable from "../../components/DistrictsTable/DistrictsTable";
import { Button } from "carbon-components-react";
import { DistrictsContext } from "../../../../context/DistrictsContext/DistrictsContext";
import CustomModal from "../../../../components/CustomModal/CustomModal";
import CreateCoordinator from "../../components/CreateCoordinator";

type Props = {};

const Districts: FunctionComponent<Props> = () => {
  const {
    session: { districts },
  } = React.useContext(DistrictsContext);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const onCreateCoordinator = () => {
    setIsModalOpen(true);
  };
  const onCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <Styled.Districts>
      {districts && (
        <DistrictsTable
          districts={districts}
          title={"Okręgi"}
          description={"Tu znajdziesz wszystkie okręgi i ich koordynatorów"}
        />
      )}
      {isModalOpen && (
        <CustomModal
          heading="Stwórz nowego koordynatora okręgu"
          label="Tworzenie koordynatora"
          onRequestCloseFunction={onCloseModal}
        >
          <CreateCoordinator onCloseModalRequestFunction={onCloseModal} />
        </CustomModal>
      )}
      <Styled.ButtonWrapper>
        <Button onClick={onCreateCoordinator}>Stwórz koordynatora</Button>
      </Styled.ButtonWrapper>
    </Styled.Districts>
  );
};

export default Districts;
