import styled, { css } from "styled-components";
export const MainWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  gap: 2rem;
  padding-right: 3rem;
`;
export const StudentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #bae6ff;
  padding: 2rem;
  border-radius: 10px;
`;

export const SchoolWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem;
  background-color: #9ef0f0;
  border-radius: 10px;
`;
export const ColumnWrapper = styled.div``;
export const LeftColumn = styled.div``;
export const RightColumn = styled.div``;
export const Title = styled.h2`
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1rem;

  &.district {
    margin-top: 2rem;
  }
`;

export const RowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  justify-content: space-between;
  margin-bottom: 2rem;
  flex-wrap: nowrap;
`;

export const Label = styled.p`
  font-weight: bold;
  margin-bottom: 0.4rem;
`;

export const Detail = styled.p`
  margin-bottom: 0.4rem;
`;

export const AddressWrapper = styled.div``;
