import React, { FunctionComponent } from "react";
import * as Styled from "../Managing.styled";
import { Button } from "carbon-components-react";

type Props = {
  openModalHandler: () => void;
  options?: any;
};

const StageII: FunctionComponent<Props> = ({ openModalHandler, options }) => {
  return (
    <Styled.Container>
      <Styled.Wrapper>
        <Styled.Header>Zarządzenie Etapem II</Styled.Header>
        <Styled.DateWrapper></Styled.DateWrapper>
        <Styled.ButtonsWrapper>
          {options && !options[2].value && (
            <Button onClick={openModalHandler}>Opublikuj wyniki</Button>
          )}
          {options && options[2].value && (
            <p style={{ color: "black" }}>Wyniki zostały już opublikowane</p>
          )}
        </Styled.ButtonsWrapper>
      </Styled.Wrapper>
    </Styled.Container>
  );
};

export default StageII;
