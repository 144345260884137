import styled, { css } from "styled-components";

export const ModalWrapper = styled.div`
  display: flex;
  form {
    width: 100%;
  }
`;
export const ButtonsWrapper = styled.div`
  gap: 2rem;
  display: flex;
`;
