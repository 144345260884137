import { authReducer } from "./AuthReducer";
import React, { useState } from "react";
import api from "../services/api";
import CurrentUser from "../types/CurrentUser";
import { AxiosError } from "axios";
import { AuthContext } from "./AuthContext";

type Props = {
  children?: React.ReactNode;
};
export const AuthProvider: React.FC<Props> = ({ children }) => {
  const [session, dispatch] = React.useReducer(authReducer, {
    user: null,
    state: "LOGGED_OUT",
  });
  const loadUser = () => {
    return api
      .get("/current-user")
      .then(({ data }: { data: CurrentUser }) => {
        dispatch({ type: "LOGIN", user: data });
      })
      .catch((error: AxiosError) => {
        console.log("problem with current-user");
        return Promise.reject(error);
      });
  };

  React.useEffect(() => {
    loadUser();
  }, []);

  return (
    <AuthContext.Provider value={{ session, dispatch, loadUser }}>
      {children}
    </AuthContext.Provider>
  );
};
