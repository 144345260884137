import styled, { css } from "styled-components";
import {
  BaseContainer,
  BaseFormWrapper,
  BasicText,
  HeaderH2,
  HeaderH3,
} from "../../../../styles/BasicStyles";

export const Managing = styled.div``;
export const Container = styled.div`
  ${BaseContainer};
  background-color: #f4f4f4;
  .cds--text-input-wrapper .cds--text-input {
    color: black;
  }

  .cds--label {
    color: black;
  }
`;
export const Wrapper = styled.div`
  ${BaseFormWrapper};
  width: 50vw;
`;

export const Header = styled.h2`
  ${HeaderH2};
  color: black;
`;

export const DateWrapper = styled.h2`
  display: flex;
  align-items: center;
  gap: 2rem;
  border-bottom: 1px solid grey;
  margin-bottom: 6rem;
  margin-top: 3rem;
`;
export const SubHeader = styled.h3`
  ${HeaderH3};
  color: black;
`;

export const Text = styled.p`
  ${BasicText};
  color: black;
`;

export const ModalWrapper = styled.div`
  display: flex;
  form {
    width: 100%;
  }
`;

export const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 3rem;
`;
