import styled from "styled-components";
import ThemeStyles from "../../../../styles/ThemeStyles";
import {
  BaseContainer,
  BasicDescending,
  BasicText,
  HeaderH1,
  Section,
} from "../../../../styles/BasicStyles";

export const WelcomeSection = styled(Section)`
  background-color: ${ThemeStyles.colors.sections.welcomeSection};
`;

export const Container = styled.div`
  ${BaseContainer};
`;

export const Greetings = styled.h1`
  ${HeaderH1}
`;

export const Descending = styled.p`
  ${BasicDescending};
`;

export const ButtonWrapper = styled.div`
  margin-top: 5rem;
  display: flex;
  flex-direction: row-reverse;

  gap: 4rem;
`;

export const ModalWrapper = styled.div`
  display: flex;
  form {
    width: 100%;
  }
`;
export const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const Text = styled.p`
  ${BasicText};
  margin-bottom: 3rem;
  margin-top: 3rem;
`;
