import styled, { css } from "styled-components";
import {
  BaseAdminScreen,
  BaseContainer,
  BaseFormWrapper,
  BasicErrorMessage,
  HeaderH1,
  HeaderH3,
} from "../../styles/BasicStyles";
import { mediaDown } from "../../styles/Mixins";
import ThemeStyle from "../../styles/ThemeStyles";

export const LoginScreen = styled.div`
  a {
    color: #fff;

    &:hover {
      color: #3ddbd9;
    }
  }

  ${BaseAdminScreen};
  background-color: #121619;
  display: grid;
  grid-template-columns: 1fr 1fr;

  ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      grid-template-columns: 2fr 1fr;
    `
  )}
  .cds--label {
    margin-bottom: 16px;
  }

  .cds--text-input--md {
    padding-left: 0.5rem;
  }

  .cds--form__helper-text {
    color: grey;
  }

  button {
    padding: calc(0.875rem - 3px) 15px calc(0.875rem - 3px) 15px;
    background-color: #007d79;
  }
`;
export const Container = styled.div`
  ${BaseContainer};
  height: 100%;
  justify-content: center;
  display: flex;
  flex-direction: column;

  ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      padding: 0;
      justify-content: flex-start;
      padding-left: 3rem;
      height: 100vh;
      padding-top: 3rem;
    `
  )}
`;

export const SecondContainer = styled.div`
  ${BaseContainer};
  justify-content: center;
  display: flex;
  flex-direction: column;
  color: #ee5396;
  align-items: flex-start;
  gap: 10rem;

  .scrambleText {
    font-size: 5rem;
  }

  ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      justify-content: flex-start;

      .scrambleText {
        font-size: 4rem;
      }
    `
  )}

  ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      padding: 0;
      padding-top: 3rem;
    `
  )}
`;

export const FormWrapper = styled.div`
  ${BaseFormWrapper};
  margin-bottom: 5rem;
  margin-top: 2rem;
  width: 25vw;
  /* From https://css.glass */
  background: rgba(184, 182, 183, 0.06);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(1.8px);
  -webkit-backdrop-filter: blur(1.8px);
  border: 1px solid rgba(184, 182, 183, 0.3);
`;

export const FormInnerWrapper = styled.div`
  padding-left: 1.6rem;
`;

export const Header = styled.h1`
  ${HeaderH1};
`;

export const SubHeader = styled.h3`
  ${HeaderH3};
  margin-bottom: 4rem;
`;

export const TitleWrapper = styled.div`
  background-color: #121619;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 3rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
`;

export const ErrorMessage = styled.p`
  ${BasicErrorMessage}
`;
