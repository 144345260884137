import React, { FunctionComponent } from "react";
import * as Styled from "./ProgressBar.styled";
import RocketIcon from "../../../../../icons/RocketIcon";
import CircleIcon from "../../../../../icons/CircleIcon";
import { max } from "@popperjs/core/lib/utils/math";

type Props = {
  value: string | undefined;
  isWinner: boolean;
  maxValue: string | undefined;
  title: string;
  descending: string;
};

const ProgressBar: FunctionComponent<Props> = ({
  value,
  isWinner,
  maxValue,
  title,
  descending,
}) => {
  return (
    <Styled.ProgressBar>
      <Styled.TitleWrapper>
        <Styled.BarTitle>
          {title} {value}/{maxValue}
        </Styled.BarTitle>
        {isWinner ? <RocketIcon /> : <CircleIcon />}
      </Styled.TitleWrapper>
      <Styled.Bar>
        <Styled.InnerBar
          isWinner={isWinner}
          width={(Number(value) / Number(maxValue)) * 100}
          maxValue={Number(maxValue)}
        />
      </Styled.Bar>
      <Styled.Descending isWinner={isWinner}>{descending}</Styled.Descending>
    </Styled.ProgressBar>
  );
};

export default ProgressBar;
