import styled from "styled-components";
import {
  BaseContainer,
  BasicDescending,
  HeaderH2,
  Section,
} from "../../../../styles/BasicStyles";
import ThemeStyle from "../../../../styles/ThemeStyles";

export const ThirdLevelSection = styled(Section)`
  background-color: ${ThemeStyle.colors.sections.thirdLevelSection};
`;

export const Container = styled.div`
  ${BaseContainer};
`;

export const Title = styled.h2`
  ${HeaderH2}
`;

export const Descending = styled.p`
  ${BasicDescending}
`;
