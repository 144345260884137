import React, { FunctionComponent } from "react";
import { Coordinator } from "../../types/Coordinator";
import * as Styled from "./Coordinator.styled";

type Props = {
  coordinators: Coordinator[];
};

const Coordinators: FunctionComponent<Props> = ({ coordinators }) => {
  return (
    <Styled.Coordinators>
      <Styled.Title>Koordynatorzy okręgu</Styled.Title>
      {coordinators.map((c) => (
        <Styled.Coordinator key={c.id}>
          <Styled.Label>{c.name}</Styled.Label>
          <Styled.Detail>{c.email}</Styled.Detail>
        </Styled.Coordinator>
      ))}
    </Styled.Coordinators>
  );
};

export default Coordinators;
