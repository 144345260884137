import React, { FunctionComponent, useEffect, useState } from "react";
import * as Styled from "./ExpandedRow.styled";
import { Button } from "carbon-components-react";
import api from "../../../../../../services/api";
import { District } from "../../../../../../types/District";
import Coordinators from "../../../../../../components/CoordinatorsTable/Coordinators";
import { Coordinator } from "../../../../../../types/Coordinator";

export type Methods = "acceptStudent";

type Props = {
  district: District;
  buttonText?: string;
  buttonMethod?: Methods;
  isRowButton: boolean;
};

const ExpandedRow: FunctionComponent<Props> = ({
  district,
  buttonMethod,
  buttonText,
  isRowButton,
}) => {
  const [coordinators, setCoordinators] = useState([]);

  const getCoordinators = () => {
    api.get("/coordinators").then((r) => {
      setCoordinators(
        r.data["hydra:member"]
          .map((c: Coordinator) => ({
            ...c,
            id: c.id.toString(),
          }))
          .filter((c: Coordinator) => {
            return c.district === district["@id"];
          })
      );
    });
  };

  const acceptStudent = () => {
    api.put(`/students/${district.id}/accept`).then(() => {});
  };

  const onClickFunction = (inputText: Methods | undefined) => {
    if (inputText === "acceptStudent") {
      return acceptStudent;
    }
  };

  useEffect(() => {
    getCoordinators();
  }, []);

  return (
    <>
      <Styled.ExpandedRow>
        <Styled.Wrapper>
          <Coordinators coordinators={coordinators} />
        </Styled.Wrapper>
        {isRowButton && (
          <Button onClick={onClickFunction(buttonMethod)}>{buttonText}</Button>
        )}
      </Styled.ExpandedRow>
    </>
  );
};

export default ExpandedRow;
