import React, { FunctionComponent } from "react";
import { Button, Modal } from "carbon-components-react";
import ReactDom from "react-dom";
import * as Styled from "./CustomModal.styled";
type Props = {
  heading?: string;
  label?: string;
  primaryButtonText?: string;
  primaryButtonFunction?: any;
  secondaryButtonText?: string;
  secondaryButtonFunction?: any;
  onRequestCloseFunction?: any;
  children?: any;
};

const CustomModal: FunctionComponent<Props> = ({
  heading,
  label,
  primaryButtonText,
  secondaryButtonText,
  onRequestCloseFunction,
  children,
  primaryButtonFunction,
  secondaryButtonFunction,
}) => {
  return ReactDom.createPortal(
    <Modal
      open
      modalHeading={heading ? heading : ""}
      modalLabel={label ? label : ""}
      onRequestClose={onRequestCloseFunction}
    >
      {children}

      <Styled.ModalWrapper>
        <Styled.ButtonsWrapper>
          {primaryButtonText && (
            <Button
              onClick={() => {
                primaryButtonFunction();
              }}
            >
              {primaryButtonText}
            </Button>
          )}
          {secondaryButtonText && (
            <Button
              onClick={() => {
                secondaryButtonFunction();
              }}
            >
              {secondaryButtonText}
            </Button>
          )}
        </Styled.ButtonsWrapper>
      </Styled.ModalWrapper>
    </Modal>,
    document.getElementById("modalPortal") as Element
  );
};

export default CustomModal;
