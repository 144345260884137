import styled, { css } from "styled-components";

type Props = {
  isWinner: boolean;
  width?: number;
  maxValue?: number;
};

export const ProgressBar = styled.div`
  width: 30rem;
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.8rem;
`;

export const BarTitle = styled.p`
  font-size: 1.2rem;
`;

export const Bar = styled.div`
  width: 100%;
  background-color: white;
  height: 5px;
`;

export const InnerBar = styled.div<Props>`
  height: 100%;
  background-color: #eb6200;
  width: 0%;

  ${(props: Props) =>
    props.isWinner &&
    css`
      background-color: #42be65;
    `}

  ${(props: Props) =>
    props.width &&
    props.maxValue &&
    css`
      width: ${props.width}%;
    `}
`;

export const Descending = styled.p<Props>`
  margin-top: 1rem;
  font-size: 1.2rem;
  color: #eb6200
    ${(props: Props) =>
      props.isWinner &&
      css`
        color: #42be65;
      `};
`;
