import { District } from "../../types/District";
import { SessionStateType } from "./DistrictsContext";

export type BaseAction = {
  type: "LOAD_DISTRICTS" | "LOADED_DISTRICTS";
  districts?: District[] | null;
};

export const districtsReducer = (
  previousState: SessionStateType,
  action: BaseAction
): SessionStateType => {
  switch (action.type) {
    case "LOAD_DISTRICTS":
      if (action.districts === undefined || action.districts === null) {
        throw Error(
          "User has to be provided into authReducer when LOGIN action is declared"
        );
      }
      return {
        ...previousState,
        state: "LOADED_DISTRICTS",
        districts: action.districts,
      };
    default:
      return previousState;
  }
};
