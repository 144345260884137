import styled from "styled-components";
import ThemeStyles from "../../../../styles/ThemeStyles";
import {
  BaseContainer,
  BasicDescending,
  BasicText,
  HeaderH2,
  HeaderH3,
  Section,
} from "../../../../styles/BasicStyles";

export const FirstLevelSection = styled(Section)`
  background-color: ${ThemeStyles.colors.sections.firstLevelSection};

  .cds--file__drop-container {
    font-size: 1rem;
  }
`;

export const Container = styled.div`
  ${BaseContainer};
`;

export const Title = styled.h2`
  ${HeaderH2};
  font-weight: bold;
`;
export const Subtitle = styled.h2`
  ${HeaderH2};
  font-weight: bold;
`;

export const Descending = styled.p`
  ${BasicDescending};
`;

export const FileName = styled.p`
  ${BasicText};
  font-weight: bold;
`;
export const FilesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
export const FileWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 3rem;
`;

export const Date = styled.p`
  ${BasicText};
  display: flex;
  gap: 1rem;
`;

export const ActualFile = styled.p`
  ${BasicText};
  font-weight: bold;
  color: ${ThemeStyles.colors.notificationColors.success};
`;
