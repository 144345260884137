import React, { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";
import api from "../../services/api";
import { Button } from "carbon-components-react";

type Props = {};

const LogoutButton: FunctionComponent<Props> = () => {
  const navigate = useNavigate();
  const logoutFun = () => {
    api.post("/logout").then(() => {
      navigate("/");
      window.location.reload();
    });
  };

  return (
    <Button
      onClick={() => {
        logoutFun();
      }}
      style={{ marginTop: "3rem" }}
    >
      Wyloguj
    </Button>
  );
};

export default LogoutButton;
