import React from "react";
import Student from "../../types/Student";

export type SessionStateType = {
  state: "LOAD_STUDENTS" | "LOADED_STUDENTS";
  pendingStudents: Student[] | null;
  acceptedStudents: Student[] | null;
};

export type AuthContextType = {
  dispatch: React.Dispatch<any>;
  session: SessionStateType;
  loadStudents: () => void;
};

export const StudentsContext = React.createContext<AuthContextType>({
  dispatch: () => {},
  loadStudents: () => {},
  session: {
    pendingStudents: null,
    acceptedStudents: null,
    state: "LOAD_STUDENTS",
  },
});
