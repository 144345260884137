import React, { FunctionComponent } from "react";

type Props = {};

const Authors: FunctionComponent<Props> = (props) => {
  return (
    <p style={{ paddingLeft: "1rem", color: "#808080", fontSize: "1.2rem" }}>
      Created by{" "}
      <a
        href={"https://www.linkedin.com/in/przemys%C5%82aw-wojenka-53a973176/"}
        target={"_blank"}
        rel={"noopener"}
      >
        Przemysław Wojenka
      </a>{" "}
      &{" "}
      <a
        href={"https://www.linkedin.com/in/hubert-sosi%C5%84ski-334bb473/"}
        target={"_blank"}
        rel={"noopener"}
      >
        Hubert Sosiński
      </a>
    </p>
  );
};

export default Authors;
