import styled, { css } from "styled-components";
import { BaseContainer, HeaderH1, HeaderH3 } from "../../styles/BasicStyles";

export const ResetPassword = styled.div`
  width: 100%;
  height: 100vh;
  background: #0f2027; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #2c5364,
    #203a43,
    #0f2027
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #2c5364,
    #203a43,
    #0f2027
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  display: flex;
  flex-direction: column;
`;

export const Container = styled.div`
  ${BaseContainer};
  height: 100vh;
  justify-content: center;
  display: flex;
  flex-direction: column;
`;

export const FormWrapper = styled.div`
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.06);
  border-radius: 11px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(7.1px);
  -webkit-backdrop-filter: blur(7.1px);
  padding: 2rem;
  width: 40%;
  margin-top: 3rem;
  margin-bottom: 3rem;
`;

export const FormInnerWrapper = styled.div``;

export const Header = styled.h1`
  ${HeaderH1};
`;

export const SubHeader = styled.h3`
  ${HeaderH3}
`;

export const ButtonWrapper = styled.div`
  padding-left: 2rem;
  margin-top: 3rem;
`;

export const Dropdown = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 30%;
  color: black;
  pointer-events: none;
`;
