import React, { useEffect } from "react";
import { AxiosError } from "axios";
import { districtsReducer } from "./DistrictsReducer";
import api from "../../services/api";
import { District } from "../../types/District";
import { DistrictsContext } from "./DistrictsContext";
import districts from "../../pages/AdminPage/screens/Disctricts/Districts";

type Props = {
  children?: React.ReactNode;
};
export const DistrictsProvider: React.FC<Props> = ({ children }) => {
  const [session, dispatch] = React.useReducer(districtsReducer, {
    districts: null,
    state: "LOAD_DISTRICTS",
  });

  const loadDistricts = () => {
    return api
      .get("/public/districts")
      .then((r) => {
        dispatch({
          type: "LOAD_DISTRICTS",
          districts: r.data["hydra:member"].map((d: District) => ({
            ...d,
            id: d.id.toString(),
          })),
        });
      })
      .catch((error: AxiosError) => {
        console.log("problem with districts");
        return Promise.reject(error);
      });
  };

  useEffect(() => {
    loadDistricts();
  }, []);
  return (
    <DistrictsContext.Provider value={{ session, dispatch, loadDistricts }}>
      {children}
    </DistrictsContext.Provider>
  );
};
