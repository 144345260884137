import React, { FunctionComponent } from "react";
import { Button } from "carbon-components-react";
import CustomModal from "../../../../../components/CustomModal/CustomModal";
import * as Styled from "../Managing.styled";

type Props = {
  onCloseModal: () => void;
  publishGrades: () => void;
};

const StageIIIModal: FunctionComponent<Props> = ({
  onCloseModal,
  publishGrades,
}) => {
  return (
    <CustomModal
      heading={
        "Czy na pewno chcesz opublikować wyniki? Wszyscy uczniowie zobaczą wyniki trzeciego etapu i zostaną" +
        " poinformowani o tym mailowo, tej opcji nie można cofnąć"
      }
      label={"Publikacja wyników"}
      onRequestCloseFunction={onCloseModal}
    >
      <Styled.ModalWrapper>
        <Button onClick={publishGrades}>Opublikuj</Button>
      </Styled.ModalWrapper>
    </CustomModal>
  );
};

export default StageIIIModal;
