import React, { FunctionComponent, useEffect, useState } from "react";
import * as Styled from "./PendingStudents.styled";
import StudentsTable from "../StudentsTable/StudentsTable";
import { StudentsContext } from "../../context/StudentsContext/StudentsContext";
import { Loading } from "carbon-components-react";
import CustomModal from "../CustomModal/CustomModal";
import { OptionsContext } from "../../context/OptionsContext/OptionsContext";

type Props = {};

const PendingStudents: FunctionComponent<Props> = () => {
  const {
    session: { pendingStudents },
  } = React.useContext(StudentsContext);
  const {
    session: { options },
  } = React.useContext(OptionsContext);

  return (
    <Styled.PendingStudents>
      {pendingStudents && options ? (
        <StudentsTable
          students={pendingStudents}
          options={options}
          title={"Uczniowie oczekujący na zatwierdzenie"}
          description={
            "Poniżej znajdziesz studentów, którzy oczekują na zatwierdzenie, aby wziąć udział w Olimpiadzie Filozoficznej. " +
            "Dopóki nie tego nie zrobisz, nie będą mogli wgrać pracy etapu szkolnego. Pamiętaj, aby upewnić się, że ich dane są poprawne. "
          }
        />
      ) : (
        <CustomModal>
          <Loading />
        </CustomModal>
      )}
    </Styled.PendingStudents>
  );
};

export default PendingStudents;
