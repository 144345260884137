import styled from "styled-components";
import {
  BaseAdminScreen,
  BaseContainer,
  BaseDropdown,
  BaseFormWrapper,
  BasicErrorMessage,
  BasicText,
  HeaderH1,
  HeaderH2,
  HeaderH3,
} from "../../styles/BasicStyles";
import exp from "constants";

export const RegisterScreen = styled.div`
  ${BaseAdminScreen};
  background-color: #121619;

  .cds--checkbox-label-text {
    color: #fff;
  }
`;
export const Container = styled.div`
  ${BaseContainer};
  justify-content: center;
  display: flex;
  flex-direction: column;
`;

export const FormWrapper = styled.div`
  ${BaseFormWrapper}
`;

export const FormInnerWrapper = styled.div``;

export const Header = styled.h2`
  ${HeaderH2};
  padding-bottom: 0;
`;

export const SubHeader = styled.h3`
  ${HeaderH3};
  margin-bottom: 3rem;
`;

export const ButtonWrapper = styled.div`
  padding-left: 2rem;
  margin-top: 3rem;
`;

export const Dropdown = styled.div`
  ${BaseDropdown}
`;

export const Text = styled.p`
  ${BasicText};
  margin-bottom: 3rem;
  margin-top: 3rem;
  line-height: 2.2rem;
`;

export const ErrorMessage = styled.p`
  ${BasicErrorMessage}
`;
