import styled, { css } from "styled-components";
import { BasicErrorMessage } from "../../../../styles/BasicStyles";

export const Districts = styled.div`
  padding-right: 3rem;
`;
export const ButtonWrapper = styled.div`
  margin-top: 3rem;
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;
export const ModalButtonWrapper = styled.div`
  margin-top: 3rem;
  width: 100%;
  display: flex;
`;
export const ModalWrapper = styled.div`
  display: flex;
  form {
    width: 100%;
  }
`;
export const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const ErrorMessage = styled.p`
  margin-top: 3rem;
  ${BasicErrorMessage},

  font-size: 2rem;
`;
