import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";

const useRedirect = (): void => {
  const {
    session: { user },
  } = React.useContext(AuthContext);

  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      if (user.type === "admin") {
        navigate("/admin");
      } else if (user.type === "coordinator") {
        navigate("/coordinator");
      } else if (user.type === "student") {
        navigate("/student");
      }
    }
  }, [user]);
};

export default useRedirect;
