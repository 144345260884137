import axios, { AxiosInstance, AxiosPromise, AxiosResponse } from "axios";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import Base64 from "Base64";
import { HOST } from "../config";

class Api {
  __api: AxiosInstance;
  constructor() {
    this.__api = axios.create({
      baseURL: HOST,
      withCredentials: true,
    });
  }

  post(url: string, body = {}): AxiosPromise {
    return this.__api.post(url, body);
  }

  get(url: string, params = {}): AxiosPromise {
    return this.__api.get(url, { params });
  }

  put(url: string, body = {}): AxiosPromise {
    return this.__api.put(url, body);
  }

  delete(url: string, body = {}): AxiosPromise {
    return this.__api.delete(url, body);
  }
}

const api = new Api();

export default api;
