import { motion } from "framer-motion";
import React, { FunctionComponent } from "react";
import * as Styled from "./OwlSvg.styled";

type Props = {};

const OwlSvg: FunctionComponent<Props> = () => {
  const transition = { duration: 4, linear: Infinity, ease: "easeIn" };
  return (
    <Styled.Wrapper>
      <svg viewBox="0 0 466 466">
        <motion.path
          className={"line"}
          d="M139.8,45c4.143,0,7.5-3.357,7.5-7.5s-3.357-7.5-7.5-7.5c-12.253,0-23.152,5.907-30,15.023
		C102.953,35.907,92.053,30,79.8,30c-4.143,0-7.5,3.357-7.5,7.5s3.357,7.5,7.5,7.5c12.406,0,22.5,10.094,22.5,22.5
		c0,4.143,3.357,7.5,7.5,7.5s7.5-3.357,7.5-7.5C117.3,55.094,127.394,45,139.8,45z"
          fill="transparent"
          strokeWidth="1"
          strokeLinecap="round"
          initial={{ pathLength: 0, stroke: "rgba(255, 255, 255, 0.69)" }}
          animate={{ pathLength: 1, stroke: "#3ddbd9" }}
          transition={transition}
        />
        <motion.path
          className={"line"}
          fill="transparent"
          strokeWidth="1"
          stroke="rgba(255, 255, 255, 0.69)"
          strokeLinecap="round"
          initial={{ pathLength: 0, stroke: "rgba(255, 255, 255, 0.69)" }}
          animate={{ pathLength: 1, stroke: "#3ddbd9" }}
          transition={transition}
          d="M422.411,424.297L200.338,96.142c-5.44-8.039-13.984-14.288-24.005-17.705c0.642-12.645,0.967-26.383,0.967-40.937v-30
		c0-4.143-3.357-7.5-7.5-7.5h-120c-4.143,0-7.5,3.357-7.5,7.5v30c0,57.634,5.026,100.198,15.819,133.955
		c11.204,35.041,28.415,59.516,45.405,80.706c3.173,5.901,6.513,11.827,9.749,17.563c18.762,33.257,38.02,67.4,45.635,128.945
		c0.011,0.503,0.07,0.993,0.176,1.467c1.819,15.173,2.938,31.993,3.171,50.864h-52.454c-4.143,0-7.5,3.357-7.5,7.5
		s3.357,7.5,7.5,7.5h60h60c4.143,0,7.5-3.357,7.5-7.5c0-19.311-2.089-36.706-5.754-52.5h20.321c4.143,0,7.5-3.357,7.5-7.5v-65.549
		c0-4.143-3.357-7.5-7.5-7.5c-4.143,0-7.5,3.357-7.5,7.5V391h-16.913c-16.582-52.774-51.15-86.389-80.492-114.912
		c-11.674-11.348-22.7-22.066-31.316-32.786c-0.024-0.03-0.048-0.06-0.072-0.09c-0.005-0.006-0.01-0.013-0.016-0.02
		C76.087,194.048,57.299,150.686,57.299,37.5V15h105v22.5c0,16.614-0.43,32.124-1.277,46.099c-0.225,3.711,2.303,7.027,5.941,7.793
		c8.797,1.853,16.63,6.771,20.952,13.157l133.232,196.875c-6.405,0.715-12.873,1.077-19.335,1.077c-94.841,0-172-77.159-172-172
		c0-4.143-3.357-7.5-7.5-7.5s-7.5,3.357-7.5,7.5c0,103.112,83.888,187,187,187c9.625,0,19.265-0.744,28.726-2.201l79.451,117.404
		c2.321,3.432,6.984,4.331,10.414,2.008C423.834,432.39,424.733,427.727,422.411,424.297z M177.248,451
		c-0.203-16.354-1.068-31.286-2.477-45h21.627c4.143,0,7.5-3.357,7.5-7.5v-26.824c4.119,8.688,7.695,17.986,10.572,28.015
		c0.098,0.612,0.262,1.201,0.498,1.754c4.094,14.811,6.668,31.201,7.22,49.556H177.248z M188.898,345.563V391h-15.945
		c-5.984-42.622-17.451-72.693-29.973-97.849C158.902,308.727,175.227,325.423,188.898,345.563z"
        />
      </svg>
    </Styled.Wrapper>
  );
};

export default OwlSvg;
