import styled, { css } from "styled-components";
import ThemeStyle from "./ThemeStyles";

export const Section = styled.section`
  width: 100%;
  height: 100%;
  min-height: 45vh;
`;

export const BaseContainer = () => css`
  display: flex;
  flex-direction: column;
  background-color: inherit;
  padding: clamp(1rem, 0.6rem + 2vw, 3rem)
    clamp(0.25rem, 0.15rem + 0.5vw, 0.75rem) clamp(6rem, 5.2rem + 4vw, 10rem)
    clamp(4rem, 3.6rem + 2vw, 6rem);
`;

export const BaseAdminScreen = () => css`
  width: 100%;
  height: 100%;
  background-color: #121619;

  display: flex;
  flex-direction: column;
`;

export const BaseFormWrapper = () => css`
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.06);
  border-radius: 11px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(7.1px);
  -webkit-backdrop-filter: blur(7.1px);
  padding: 2rem;
  width: 30vw;
  min-width: 25rem;
  margin-top: 3rem;
  margin-bottom: 3rem;
`;

export const BaseDropdown = () => css`
  position: absolute;
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.06);
  border-radius: 11px;
  //box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  //backdrop-filter: blur(7.1px);
  //-webkit-backdrop-filter: blur(7.1px);
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  z-index: 999;
`;

export const TextStyling = () => css`
  font-family: ${ThemeStyle.fonts.main};
  color: ${ThemeStyle.colors.primary};
  letter-spacing: 0;
  font-weight: 300;
`;

export const HeaderH1 = () => css`
  ${TextStyling()};
  font-size: clamp(3rem, 2.2rem + 4vw, 7rem);
  padding-bottom: 2rem;
`;

export const HeaderH2 = () => css`
  ${TextStyling()};
  font-size: clamp(
    0.875rem,
    0.5249999999999999rem + 1.7500000000000002vw,
    2.625rem
  );
  line-height: 3.125rem;
  padding-bottom: 2rem;
`;

export const HeaderH3 = () => css`
  ${TextStyling()};
  font-size: clamp(1rem, 1.6vw + 1.2rem, 2.2rem);
`;

export const BasicText = () => css`
  ${TextStyling()};
  color: ${ThemeStyle.colors.secondary};
  font-size: clamp(1rem, 0.88rem + 0.6000000000000001vw, 1.6rem);
  font-weight: 400;
  line-height: 1.125rem;
  letter-spacing: 0.16px;
`;
export const BasicErrorMessage = () => css`
  ${BasicText()};
  font-size: clamp(0.8rem, 0.88rem + 0.6000000000000001vw, 1.2rem);
  color: red;
`;
export const BasicDescending = () => css`
  ${BasicText()};
  padding-bottom: 6rem;
`;

export const StyledButton = styled.a`
  padding: 16px 25px;
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
  transition-property: background-color, color;

  &:hover {
  }
`;
