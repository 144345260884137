import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from "react";
import * as Styled from "./ThirdLevelSection.styled";
import { ApplicationsContext } from "../../../../context/ApplicationsContext/ApplicationsContext";
import api from "../../../../services/api";
import ProgressBar from "../FirstLevelSection/components/ProgressBar";
import { OptionsContext } from "../../../../context/OptionsContext/OptionsContext";
import Notification from "../../../../components/Notification/Notification";
type Props = {};

const ThirdLevelSection: FunctionComponent<Props> = (props) => {
  const {
    session: { applications },
    loadApplications,
  } = React.useContext(ApplicationsContext);

  const [arePointsPublished, setArePointsPublished] = useState<
    boolean | undefined
  >();

  const [grades, setGrades] = useState<{
    firstGrade: number;
    comment: string;
  }>();

  const [points, setPoints] = useState<number>();
  const {
    session: { options },
    loadOptions,
  } = React.useContext(OptionsContext);

  const getArePointsPublished = useCallback(() => {
    if (options && options.length > 0) {
      setArePointsPublished(options[5].value);
    }
  }, [options]);

  useEffect(() => {
    loadOptions();
  }, []);
  useEffect(() => {
    if (!arePointsPublished) {
      getArePointsPublished();
      loadApplications();
    }
  }, [options]);

  useEffect(() => {
    if (applications && applications?.length > 0) {
      setGrades(applications[0].gradeThirdStage);
    }
  }, [applications]);

  useEffect(() => {
    if (grades) {
      setPoints(grades.firstGrade);
    }
  }, [grades]);

  useEffect(() => {
    console.log("elo", arePointsPublished);
  }, [arePointsPublished, options]);
  return (
    <Styled.ThirdLevelSection>
      <Styled.Container>
        <Styled.Title>ETAP III</Styled.Title>
        <Styled.Descending></Styled.Descending>
        <Notification
          kind={"success"}
          title={"Etap III odbędzie się 05.04.2025 r."}
        />
        <br></br>
        {arePointsPublished && (
          <>
            <Styled.Title style={{ marginTop: "5rem" }}>
              Wyniki zostały opublikowane!
            </Styled.Title>
            <Styled.Descending></Styled.Descending>
          </>
        )}
        {arePointsPublished ? (
          grades?.firstGrade || grades?.firstGrade === 0 ? (
            <ProgressBar
              value={`${grades?.firstGrade}`}
              isWinner={true}
              maxValue={"100"}
              title={"Twój wynik:"}
              descending={""}
            />
          ) : (
            <Styled.Descending>Brak punktów</Styled.Descending>
          )
        ) : null}

        {arePointsPublished ? (
          grades?.comment ? (
            <>Komentarz: {grades.comment}</>
          ) : (
            <Styled.Descending></Styled.Descending>
          )
        ) : null}
      </Styled.Container>
    </Styled.ThirdLevelSection>
  );
};

export default ThirdLevelSection;
