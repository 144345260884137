import styled, { css } from "styled-components";
import ThemeStyle from "../../styles/ThemeStyles";
type ColorLabelProps = {
  color: string;
};
type CircleIndicatorProps = {
  isActive?: boolean | null | undefined;
};

export const ColorLabelWrapper = styled.div`
  display: flex;
  padding: 0.6rem 0;
`;

export const ColorLabel = styled.div<ColorLabelProps>`
  padding: 1rem 2rem;
  display: flex;
  border-radius: 75px;
  font-weight: bolder;
  justify-content: center;
  width: 70%;

  ${(props: ColorLabelProps) =>
    props.color &&
    css`
      background-color: ${props.color};
    `}
`;

export const LabelTitle = styled.p``;

export const CirclesWrapper = styled.div`
  display: flex;
  padding: 0.6rem 0;
`;

export const CircleIndicator = styled.div<CircleIndicatorProps>`
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  margin-left: 1rem;
  ${(props: CircleIndicatorProps) =>
    props.isActive &&
    css`
      background-color: ${ThemeStyle.colors.notificationColors.success};
    `}
  ${(props: CircleIndicatorProps) =>
    !props.isActive &&
    css`
      background-color: grey;
    `}
`;
