import React, { useEffect } from "react";
import { AxiosError } from "axios";
import api from "../../services/api";
import { Application } from "../../types/Application";
import { ApplicationsContext } from "./ApplicationsContext";
import { applicationsReducer } from "./ApplicationsReducer";

type Props = {
  children?: React.ReactNode;
};
export const ApplicationsProvider: React.FC<Props> = ({ children }) => {
  const [session, dispatch] = React.useReducer(applicationsReducer, {
    applications: null,
    state: "LOAD_APPLICATIONS",
  });

  const loadApplications = () => {
    return api
      .get("/applications")
      .then((r) => {
        dispatch({
          type: "LOAD_APPLICATIONS",
          applications:
            r.data["hydra:member"] &&
            r.data["hydra:member"].map((a: Application) => ({
              ...a,
            })),
        });
      })
      .catch((error: AxiosError) => {
        console.log("problem with applications");
        return Promise.reject(error);
      });
  };

  useEffect(() => {
    if (!session.applications) {
      loadApplications();
    }
  }, [session.applications]);

  return (
    <ApplicationsContext.Provider
      value={{ session, dispatch, loadApplications }}
    >
      {children}
    </ApplicationsContext.Provider>
  );
};
