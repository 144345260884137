import styled from "styled-components";
import React from "react";

export const InputText = styled.div`
  margin-bottom: 2rem;
`;

export const Error = styled.p`
  font-size: 0.8rem;
  font-weight: lighter;
  color: red;
`;
