import React from "react";

const useCheckAge = (value: string | null): boolean => {
  if (value) {
    let today = new Date();
    let birthDate = new Date(value);
    let age = today.getFullYear() - birthDate.getFullYear();
    let m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age < 16;
  }
  return false;
};

export default useCheckAge;
