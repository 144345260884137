import React, { FunctionComponent, useState } from "react";
import InputText from "../../../components/InputText/InputText";
import { Button, Loading, Select, SelectItem } from "carbon-components-react";
import { District } from "../../../types/District";
import { SubmitHandler, useForm } from "react-hook-form";
import api from "../../../services/api";
import { DistrictsContext } from "../../../context/DistrictsContext/DistrictsContext";

type Props = {
  onCloseModalRequestFunction: any;
};
type Inputs = {
  name: string;
  email: string;
  password: string;
  district: string;
};

const CreateCoordinator: FunctionComponent<Props> = ({
  onCloseModalRequestFunction,
}) => {
  const {
    session: { districts },
  } = React.useContext(DistrictsContext);
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<Inputs>({
    mode: "onSubmit",
    reValidateMode: "onChange",
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: false,
  });
  const [errMsg, setErrMsg] = useState<string>("");
  const [loadingState, setLoadingState] = useState<boolean>(false);

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    setLoadingState(true);
    try {
      await api.post("/coordinators", {
        name: data.name,
        email: data.email,
        password: data.password,
        district: data.district,
      });
      setLoadingState(false);
      onCloseModalRequestFunction();
      window.location.reload();
    } catch (err) {
      if (err) {
        setLoadingState(false);
        setErrMsg(
          "Wystąpił błąd, sprawdź, czy koordynator z takim adresem mail już istnieje "
        );
        //todo: czy obsłużyć inne błędy i jak? typu: taki adres mail już istnije
      }
    }
  };
  //todo: wynieść moco create coordinator i submit wżej
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <InputText
        register={register("name", {
          required: true,
        })}
        helperText={"Wprowadź imię i nazwisko"}
        id={"name"}
        labelText={"Imię i nazwisko"}
        placeholder={"Jan Kowalski"}
        disabled={false}
        invalid={false}
        errors={errors.name && "Wprowadź imię"}
      />
      <InputText
        register={register("email", {
          required: true,
          pattern: /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i,
        })}
        helperText={"Wprowadź adres e-mail"}
        id={"email"}
        labelText={"E-mail"}
        placeholder={"j.kowalski@gmail.com"}
        disabled={false}
        invalid={false}
        errors={errors.email && "Wprowadź poprawny adres e-mail"}
      />
      <InputText
        register={register("password", { required: true })}
        helperText={"Wprowadź hasło"}
        id={"password"}
        labelText={"Hasło"}
        placeholder={"Hasło"}
        disabled={false}
        invalid={false}
        errors={errors.email && "Wprowadź hasło"}
      />
      {districts && (
        <Select id={"s"} {...register("district")} labelText={"Okręg"}>
          {districts.map((d: District) => (
            <SelectItem text={d.name} value={d["@id"]} key={d.id} />
          ))}
        </Select>
      )}
      <Button type={"submit"}>Zarejestruj</Button>
      {errMsg && <p style={{ color: "red" }}>{errMsg}</p>}
      {loadingState && <Loading />}
    </form>
  );
};

export default CreateCoordinator;
