import React, { FunctionComponent } from "react";

type Props = {};

const RocketIcon: FunctionComponent<Props> = (props) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.64264 7.64727L3.64395 11.646L4.35106 12.3531L8.34975 8.35437L7.64264 7.64727Z"
        fill="#24A148"
      />
      <path
        d="M8.49991 15C8.43658 15.0001 8.3738 14.9882 8.31491 14.965C8.233 14.9323 8.16117 14.8786 8.10673 14.8092C8.05229 14.7398 8.01716 14.6573 8.00491 14.57L7.50491 11.07L8.50491 10.93L8.87991 13.565L10.4999 12.26V8.49997C10.4995 8.43417 10.5121 8.36894 10.537 8.30802C10.5619 8.2471 10.5986 8.19169 10.6449 8.14497L12.6799 6.10997C13.0992 5.6935 13.4318 5.198 13.6584 4.65216C13.8849 4.10632 14.001 3.52097 13.9999 2.92997V1.99997H13.0699C12.4789 1.99885 11.8936 2.11493 11.3477 2.34151C10.8019 2.56808 10.3064 2.90065 9.88991 3.31997L7.85491 5.35497C7.80819 5.40131 7.75278 5.43797 7.69186 5.46286C7.63094 5.48774 7.56571 5.50035 7.49991 5.49997H3.73991L2.43491 7.12997L5.06991 7.50497L4.92991 8.50497L1.42991 8.00497C1.34258 7.99272 1.26005 7.95759 1.19067 7.90315C1.1213 7.84871 1.06757 7.77689 1.03491 7.69497C1.00185 7.61248 0.991373 7.52267 1.00456 7.43479C1.01774 7.34691 1.0541 7.26413 1.10991 7.19497L3.10991 4.69497C3.15597 4.63512 3.21499 4.58649 3.28253 4.55272C3.35008 4.51894 3.4244 4.50091 3.49991 4.49997H7.29491L9.17991 2.60997C9.68949 2.09752 10.2957 1.69134 10.9635 1.41497C11.6312 1.13859 12.3472 0.997535 13.0699 0.99997H13.9999C14.2651 0.99997 14.5195 1.10533 14.707 1.29286C14.8946 1.4804 14.9999 1.73475 14.9999 1.99997V2.92997C15.0023 3.65265 14.8613 4.36863 14.5849 5.03639C14.3085 5.70414 13.9024 6.31039 13.3899 6.81997L11.4999 8.70497V12.5C11.4996 12.5751 11.4823 12.6491 11.4494 12.7166C11.4165 12.7842 11.3688 12.8434 11.3099 12.89L8.80991 14.89C8.72194 14.9606 8.61269 14.9993 8.49991 15Z"
        fill="#24A148"
      />
    </svg>
  );
};

export default RocketIcon;
