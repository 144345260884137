const ThemeStyle = {
  backgrounds: {
    main: "#121619",
  },
  colors: {
    notificationColors: {
      warning: "#F1C21B",
      success: "#24A148",
    },
    sections: {
      welcomeSection: "#491D8B",
      firstLevelSection: "#000",
      secondLevelSection: "#510224",
      thirdLevelSection: "#004144",
    },
    primary: "#F4F4F4",
    secondary: "#C6C6C6",
    purple: "#491D8B",
    black: "#000",
    magenta: "#510224",
    teal: "#004144",
  },
  fonts: {
    main: "IBM Plex Sans",
    second: "Chakra Petch, sans-serif",
  },
  weights: {
    ultralight: 100,
    light: 300,
    regular: 400,
    medium: 500,
    bold: 600,
    ultraBold: 700,
  },
  transition: "all .35s",
  padding: {
    sm: "3.5rem",
    md: "4rem",
    lg: "4rem",
    xl: "6rem",
    xxl: "9rem",
  },
  maxWidth: "1440px",
  breakpoints: {
    sm: "576px",
    mobile: "576px",
    md: "768px",
    lg: "1024px",
    xl: "1280px",
    xxl: "1460px",
    ultra: "1600px",
  },
};

export default ThemeStyle;
