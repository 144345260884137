import styled, { css } from "styled-components";
import {
  BaseAdminScreen,
  BaseContainer,
  Section,
} from "../../styles/BasicStyles";

export const CoordinatorPage = styled.div`
  ${BaseAdminScreen};
  height: 100vh;
`;
export const Container = styled.div`
  ${BaseContainer};
  padding: 5rem;
`;
