import styled from "styled-components";

export const ExpandedRow = styled.div`
  width: 100%;
  padding: 2rem 0 2rem 0;
  gap: 2rem;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
export const ColumnWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;
export const LeftColumn = styled.div``;
export const RightColumn = styled.div``;
export const Title = styled.p`
  font-size: 1.4rem;
  font-weight: bold;
  margin-bottom: 1rem;
`;
export const Label = styled.p`
  font-weight: bold;
  margin-bottom: 0.4rem;
`;
export const Detail = styled.p`
  margin-bottom: 0.4rem;
`;
