import React from "react";
import { District } from "../../types/District";

export type SessionStateType = {
  state: "LOAD_DISTRICTS" | "LOADED_DISTRICTS";
  districts: District[] | null;
};

export type AuthContextType = {
  dispatch: React.Dispatch<any>;
  session: SessionStateType;
  loadDistricts: () => void;
};

export const DistrictsContext = React.createContext<AuthContextType>({
  dispatch: () => {},
  loadDistricts: () => {},
  session: {
    districts: null,
    state: "LOAD_DISTRICTS",
  },
});
