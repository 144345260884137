import React, { FunctionComponent } from "react";
import { TextInput } from "carbon-components-react";
import * as Styled from "./InputText.styled";

type Props = {
  helperText: string;
  id: string;
  labelText: string;
  placeholder: string;
  disabled: boolean;
  invalid: boolean;
  register: any;
  required?: boolean;
  pattern?: string;
  errors?: any;
  type?: string;
  value?: string | Date;
  defaultValue?: string | Date;
  onFocus?: any;
};

const InputText: FunctionComponent<Props> = ({
  helperText,
  id,
  labelText,
  placeholder,
  disabled,
  register,
  errors,
  type,
  value,
  defaultValue,
  onFocus,
}) => {
  return (
    <Styled.InputText>
      <TextInput
        {...register}
        helperText={helperText}
        id={id}
        labelText={labelText}
        placeholder={placeholder}
        disabled={disabled}
        type={type}
        value={value}
        defaultValue={defaultValue}
        onFocus={onFocus}
      />
      <Styled.Error>{errors}</Styled.Error>
    </Styled.InputText>
  );
};

export default InputText;
