import React, { FunctionComponent, ReactNode } from "react";
import * as Styled from "./Table.styled";
import { StudentsContext } from "../../context/StudentsContext/StudentsContext";
const { Tabs, TabList, Tab, TabPanels, TabPanel } = require("@carbon/react");

type Props = {
  tabs: string[];
  tabsPanels: ReactNode[];
};

const Table: FunctionComponent<Props> = ({ tabs, tabsPanels }) => {
  const {
    session: { acceptedStudents, pendingStudents },
  } = React.useContext(StudentsContext);

  return (
    <Tabs>
      <TabList aria-label="List of tabs" activation="manual">
        {tabs.map((tab) =>
          tab === "Zgłoszenia" ? (
            <Styled.TabWrapper>
              <Styled.CustomTab>
                <Styled.IndicatorWrapper>
                  {pendingStudents?.length}
                </Styled.IndicatorWrapper>
                {tab}
              </Styled.CustomTab>
            </Styled.TabWrapper>
          ) : tab === "Zaakceptowani uczniowie" ? (
            <Styled.TabWrapper>
              <Styled.CustomTab>
                <Styled.IndicatorWrapper>
                  {acceptedStudents?.length}
                </Styled.IndicatorWrapper>
                {tab}
              </Styled.CustomTab>
            </Styled.TabWrapper>
          ) : (
            <Styled.TabWrapper>
              <Styled.CustomTab>{tab}</Styled.CustomTab>
            </Styled.TabWrapper>
          )
        )}
      </TabList>
      <TabPanels>
        {tabsPanels.map((tabPanel) => (
          <TabPanel>{tabPanel}</TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );
};

export default Table;
