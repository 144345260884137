// @ts-nocheck

import React, { FunctionComponent } from "react";
import {
  DataTable,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableExpandedRow,
  TableExpandHeader,
  TableExpandRow,
  TableHead,
  TableHeader,
  TableRow,
} from "carbon-components-react";
import ExpandedRow, { Methods } from "./components/ExpandedRow/ExpandedRow";
import { District } from "../../../../types/District";

type Props = {
  districts: District[];
  title: string;
  description: string;
  rowsButtonText?: string;
  rowsButtonMethod?: Methods;
};

type DataTableProps = {
  rows: District[];
  headers: [{ header: string }];
  getTableProps: () => {};
  getHeaderProps: ({ header }: any) => {};
  getRowProps: ({ header }: any) => {};
};

const headers = [
  {
    key: "name",
    header: "Nazwa",
  },
];

const DistrictsTable: FunctionComponent<Props> = ({
  districts,
  title,
  description,
  rowsButtonText,
  rowsButtonMethod,
}) => {
  return (
    <DataTable rows={districts} headers={headers} isSortable={false}>
      {({
        rows,
        headers,
        getTableProps,
        getHeaderProps,
        getRowProps,
      }: DataTableProps) => (
        <TableContainer title={title} description={description}>
          <Table {...getTableProps()}>
            <TableHead>
              <TableRow>
                <TableExpandHeader />
                {headers.map((header) => (
                  <TableHeader {...getHeaderProps({ header })}>
                    {header.header}
                  </TableHeader>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, idx) => (
                <React.Fragment key={row.id}>
                  <TableExpandRow {...getRowProps({ row })}>
                    {row.cells.map((cell: any, idx: number) => (
                      <TableCell key={cell.id}>
                        {idx === 2 ? cell.value.name : cell.value}
                      </TableCell>
                    ))}
                  </TableExpandRow>
                  <TableExpandedRow colSpan={headers.length + 1}>
                    <ExpandedRow
                      key={districts[idx].id}
                      district={districts[idx]}
                      buttonText={rowsButtonText}
                      buttonMethod={rowsButtonMethod}
                    />
                  </TableExpandedRow>
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </DataTable>
  );
};

export default DistrictsTable;
